// 2019.10.09: to align
// screenHelpers.js / bskTransitionDuration = bskColors.scss / $bsk-transition-duration
$bsk-transition-duration: 0.3s;
$bsk-select-transition-duration: 0.2s;

$bsk-width-threshold-1: 600px;
$bsk-width-threshold-2: 1000px;

$bsk-height-threshold-1: 500px;
$bsk-height-threshold-2: 800px;

$bsk-raw-text: #02a4f8;
$bsk-raw-beacon: #00a4f7;
$bsk-raw-beacon-dark: #0f1f30;

$bsk-opened-text: #f3db3b;
// $bsk-opened-text: #f4e161;
$bsk-opened-beacon: #fff023;
$bsk-opened-beacon-dark: #332b08;

$bsk-solved-text: #94bd15;
$bsk-solved-beacon: #9aba0e;
$bsk-solved-beacon-dark: #222807;

$bsk-rejected-text: #d3d5e8;
$bsk-rejected-beacon: #d3d5e8;
$bsk-rejected-beacon-dark: #1d1e23;

$footer-bg: #ffc000;
$footer-selected: #ffffff;
$footer-unselected: #bf9000;

$inc-header: #345c66;

$msg-light-blue-sec: #7dcbe8;
$msg-light-blue: #78c8e6;

$header-bg: #757170;
$raw-text: #757170;

$green-switch: #92d14f;

$split-line-light: #f6f6f6;
$split-line: #e9e9e9;
$bsk-bg-login: #e2e2e2;

$filter-bg: #d0cecf;
$filter-box: #e5e5e5;

$inc-label: #ff3300;
$bsk-citizen-not-visible: rgb(185, 40, 29);

$evt-title: #44546a;

$field-title: #afacab;
$bsk-link: #0000ee;
$bsk-link-visited: #551a8b;
$bsk-help-bg: #ececea;
$bsk-tuto-link: #27ace4;

$bsk-warning: $inc-label;
$bsk-good: $bsk-solved-text;
$bsk-neutral: $inc-header;

$bsk-white: #ffffff;
$bsk-black: #000000;

$bsk-unselected: #d9d9d9;

$bsk-card-border: #e8e8e8;
$bsk-card-footer-bg: #fafafa;
$bsk-blue-modal: #00b0f1;

$bsk-text-gray-dark: #888383;
$bsk-text-gray-dark-med: #979596;
$bsk-text-gray-medium: #bec5c6;

$line-height: 35px;

$form-lighter-disabled-active: #ffe699; // lighter from $footer-bg
$form-lighter-disabled: #f4f4f4; // lighter than $split-line

$yellow-hat: #ffa600;

$modal-bg: rgba(#333, 0.2);
$form-item-unselected: $bsk-unselected;
$form-item-hover: $footer-bg;
$form-item-hover-light: rgba($footer-bg, 0.3);
$form-item-active: $footer-bg;
$form-item-disabled: $split-line;
$form-item-disabled-text: #aaa;
$form-item-hover-text: $footer-unselected;

$form-normal-padding-left: 20px;
$form-small-padding-left: 10px;

$form-image-box-size: 12rem;

$transparence-active: 0.1;
$transparence-hover: $transparence-active * 2;

$facebook-color: #2f5596;
$google-color: #d0220a;

$ft-plus-size: 4rem;

.googleBgColor {
  background-color: $google-color !important;
  border-color: $google-color !important;
  &:hover {
    background-color: $google-color !important;
    border-color: $google-color !important;
  }
}
.facebookBgColor {
  background-color: $facebook-color !important;
  border-color: $facebook-color !important;
  &:hover {
    background-color: $facebook-color !important;
    border-color: $facebook-color !important;
  }
}

// $antd-base: #345c66;
// $antd-plus: #4a6870;

$antd-base: #757170;
$antd-plus: #7f7a79;

// std font-size
// $font-7: 7px;
// $font-10: 10px;
// $font-11: 11px;
// $font-12: 12px;
// $font-14: 14px;
// $font-16: 16px;
// $font-18: 18px;

// test Iphone
$font-7: 9px;
$font-10: 13px;
$font-11: 14px;
$font-12: 15px;
$font-14: 17px;
$font-16: 19px;
$font-18: 19px;

$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-26: 26px;
$font-30: 30px;
$font-32: 32px;
$font-36: 36px;
$font-38: 38px;
$font-48: 48px;
$font-1em: 1em;
$font-1-5em: 1.5em;
$font-1-16em: 1.16666667em;

$item-border-radius: 0.2rem;
$form-border-radius: 0.3rem;
$pane-border-radius: 0.6rem;
$pane-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.15);

.mt-2 {
  margin-top: 0.5rem;
}

.bsk-incident-tag-colors {
  background-color: $field-title;
  color: $bsk-white;
}

$bsk-line-height: 3rem;
$bsk-line-height-small: 2.1rem;

// 2019.10.09: to align
// constants/Screen.js / bskTransitionDuration = bskColors.scss / $bsk-transition-duration
$bsk-transition-duration: 0.3s;
$bsk-select-transition-duration: 0.2s;

.mk-down {
  text-align: left;

  p {
    margin-top: 0.7rem;
  }

  li {
    padding-left: 0.5rem;
    margin-left: 2rem;
  }

  &.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
  &.preview.w-border {
    border-radius: $form-border-radius;
    background-color: $bsk-white;
    border: 1px solid $form-item-unselected;
  }
  &.preview.w-bg {
    background-color: $split-line-light;
  }

  a:link {
    color: $bsk-link;
  }

  a:hover {
    text-decoration: underline;
  }

  a:visited {
    color: $bsk-link-visited;
  }
}

@mixin bsk-border {
  border-width: 1px;
  border-style: solid;
  border-color: $form-item-unselected;
  border-radius: $form-border-radius;

  &:hover,
  &.isOpen {
    border-color: $form-item-hover;
  }

  &.isOpen {
    box-shadow: 0 0 2px 2px rgba($footer-unselected, 0.2);
  }
}

@mixin bsk-form-lh {
  line-height: $bsk-line-height;
  min-height: $bsk-line-height;
  font-size: 1.2rem;
}

@mixin bsk-form-lh-small {
  line-height: $bsk-line-height-small;
  min-height: $bsk-line-height-small;
}

@mixin bsk-form-fs12 {
  font-size: 1.2rem;
}

$base-margin-top: 1rem;

@mixin bsk-vspace-content($factor) {
  .bsk-mt-3 {
    margin-top: 1rem * $factor !important;
  }
  .bsk-mt-5 {
    margin-top: 3rem * $factor !important;
  }
}

.bsk-screen-normal {
  &.bsk-vspace-0 {
    @include bsk-vspace-content(0.5);
  }

  &.bsk-vspace-1 {
    @include bsk-vspace-content(1);
  }

  &.bsk-vspace-2 {
    @include bsk-vspace-content(2);
  }

  &.bsk-vspace-3 {
    @include bsk-vspace-content(4);
  }

  &.bsk-vspace-4 {
    @include bsk-vspace-content(8);
  }

  &.bsk-vspace-5 {
    @include bsk-vspace-content(16);
  }
}

ul {
  // edge
  padding-left: 0px;
}

.bsk-align-right {
  text-align: right;
}

.bsk-align-center {
  text-align: center;
}

a.no-decoration {
  text-decoration: none;
  color: unset;
}

.bsk-w100 {
  width: 100%;
}

.bsk-status-raw-bg {
  background-color: $bsk-raw-text;
}
.bsk-status-raw-color {
  color: $bsk-raw-text;
}

.bsk-status-opened-bg {
  background-color: $bsk-opened-text;
}
.bsk-status-opened-color {
  color: $bsk-opened-text;
}

.bsk-status-solved-bg {
  background-color: $bsk-solved-text;
}
.bsk-status-solved-color {
  color: $bsk-solved-text;
}

.bsk-status-rejected-bg {
  background-color: $bsk-rejected-text;
}
.bsk-status-rejected-color {
  color: $bsk-rejected-text;
}

// scroll bar

.tile-content-split {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 1rem;
    // color: green;
    background-color: $bsk-white;
    display: none;
  }
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px black;
    // border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f8f8f8;
    border-radius: 1rem;
    transition: background-color $bsk-transition-duration ease-out;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $split-line;
  }
}

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%;
}

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%;
}

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%;
}

// colors of notifications (badge close to the bell)

.ngBlue {
  color: #78c8e6;
}

.ngRed {
  color: #e74917;
}

.ngYellow {
  color: #ffa600;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto;
  & > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem;
  }

  & > .text {
    flex: 1 0 1.5rem;
    width: auto;
  }
}

.bsk-tile-incident-dashboard {
  background-color: $split-line-light;
}

body .rmc-drawer-overlay {
  z-index: 899;
}

.bsk-footer-bg-bg {
  background-color: $footer-bg;
}

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0;
}
