body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/*
Author         : G-axon
Template Name  : Eau Ouest Essonne Antd React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
        02. Colors Class

    B. Base
        01. Base Styles
        02. Row Col Styles
        03. Margin Padding Styles
        04. Typography Styles

    C. Layouts
        01. Ant Layout Style
        02. Header Style
        03. Sidebar Style
        04. Ant Menu Style
        05. Footer Styles
        06. Customizer Style

    D. UI Components
        01. Avatar Styles
        02. Badges Styles
        03. Button Styles
        04. Cards Styles
        05. Cascader Styles
        06. Form Styles
        07. Input Styles
        08. Icon Styles
        09. List Styles
        10. Loader Styles
        11. Pagination Styles
        12. Pickers Styles
        13. Progress Styles
        14. Steps Styles
        15. Slider Styles
        16. Tables Styles
        17. Time Lines Style
        18. Tabs Styles

    E. Apps
        01. Apps Style
        02. Mails Apps Styles
        03. Chat Apps Styles
        04. Contact Apps Styles
        05. Calendar Apps Style

    F. Pages
        01. Callout Styles
        02. E-commerce Styles
        03. Pricing Tables Styles
        04. Login Styles
        05. Dashboard Styles
        06. Error Styles
        07. Editor Styles
        08. Testimonial Styles

  =============================================================*/
/* stylelint-disable at-rule-no-unknown */
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
}
[tabindex="-1"]:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #545454;
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type="text"],
input[type="password"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline: 0;
}
a[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
}

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-maplist-scroller {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative; }
  .bsk-maplist-scroller .bsk-maplist-scroller-tray {
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: row;
    width: auto; }
    .bsk-maplist-scroller .bsk-maplist-scroller-tray > div {
      flex: 0 0 auto;
      box-sizing: content-box; }
    .bsk-maplist-scroller .bsk-maplist-scroller-tray.scroll {
      -webkit-transition: left 0.3s ease-out;
      transition: left 0.3s ease-out; }
      .bsk-maplist-scroller .bsk-maplist-scroller-tray.scroll > div {
        -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
        transition: left 0.3s ease-out, width 0.3s ease-out; }

.tile-manager.interestScroll .bsk-maplist-scroller-tray > div {
  -webkit-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out; }

.bsk-maplist-map {
  width: 100%;
  height: 100%;
  position: relative; }

.bsk-maplist-list {
  width: 100%;
  height: 100%;
  font-size: 1rem;
  overflow-y: auto;
  overflow-x: hidden; }
  .bsk-maplist-list .bsk-maplist-incident {
    margin-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .bsk-maplist-list .bsk-maplist-incident > .force-break-t3 {
      height: 0;
      flex: 0 0 100%;
      display: none; }
    .bsk-maplist-list .bsk-maplist-incident > .separator {
      height: 0.5rem;
      width: 100%;
      background-color: #e9e9e9; }
    .bsk-maplist-list .bsk-maplist-incident > .name {
      display: flex;
      flex-direction: row; }
      .bsk-maplist-list .bsk-maplist-incident > .name > .text {
        padding-left: 1rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        flex: 0 0 auto;
        background-color: #345c66;
        color: #ffffff;
        letter-spacing: 0.1rem;
        min-width: 10rem; }
      .bsk-maplist-list .bsk-maplist-incident > .name > .icon {
        flex: 0 0 auto;
        color: #345c66;
        height: auto;
        margin-left: -2px;
        overflow: hidden;
        height: 1.5rem; }
        .bsk-maplist-list .bsk-maplist-incident > .name > .icon > svg {
          height: 1.5rem; }
    .bsk-maplist-list .bsk-maplist-incident > .status {
      display: flex;
      flex-direction: row; }
      .bsk-maplist-list .bsk-maplist-incident > .status > .text {
        flex: 1 1 auto;
        font-weight: 700;
        font-size: 1.2rem; }
      .bsk-maplist-list .bsk-maplist-incident > .status > .pad {
        flex: 0 0 auto;
        margin-left: 0.5rem;
        width: 0.5rem;
        height: 1.2rem;
        font-size: 1.2rem; }
    .bsk-maplist-list .bsk-maplist-incident > .problem-type,
    .bsk-maplist-list .bsk-maplist-incident > .queue,
    .bsk-maplist-list .bsk-maplist-incident > .address {
      display: flex;
      flex-direction: row;
      margin-top: 0.3rem;
      flex: 0 0 auto; }
      .bsk-maplist-list .bsk-maplist-incident > .problem-type > .icon,
      .bsk-maplist-list .bsk-maplist-incident > .queue > .icon,
      .bsk-maplist-list .bsk-maplist-incident > .address > .icon {
        flex: 0 0 2rem; }
        .bsk-maplist-list .bsk-maplist-incident > .problem-type > .icon > svg,
        .bsk-maplist-list .bsk-maplist-incident > .queue > .icon > svg,
        .bsk-maplist-list .bsk-maplist-incident > .address > .icon > svg {
          width: 1.5rem;
          height: 1.5rem; }
      .bsk-maplist-list .bsk-maplist-incident > .problem-type > .text,
      .bsk-maplist-list .bsk-maplist-incident > .queue > .text,
      .bsk-maplist-list .bsk-maplist-incident > .address > .text {
        flex: 1 1 auto;
        margin-left: 1rem; }
    .bsk-maplist-list .bsk-maplist-incident > .problem-type > .icon {
      color: #ff3300;
      display: none; }
    .bsk-maplist-list .bsk-maplist-incident > .problem-type > .text {
      font-size: 1.2rem;
      font-weight: 700;
      color: #ff3300;
      margin-left: 0;
      text-align: center; }
    .bsk-maplist-list .bsk-maplist-incident > .address > .icon > svg {
      width: 1.8rem;
      height: 1.8rem; }
    .bsk-maplist-list .bsk-maplist-incident > .created,
    .bsk-maplist-list .bsk-maplist-incident > .last-updated {
      font-size: 0.8rem;
      color: #afacab; }

.bsk-singlecol-true .bsk-maplist-list .bsk-maplist-incident {
  padding-left: 0;
  padding-right: 0; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .name {
  flex: 1 1 auto; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .status {
  flex: 0 0 8rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .problem-type,
.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .queue {
  flex: 0 0 100%; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .address {
  flex: 0 0 100%; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .created,
.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .last-updated {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  flex: 1 1 3rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-down .bsk-maplist-incident > .last-updated {
  text-align: right; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .name {
  flex: 0 0 10rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .status {
  flex: 1 0 7rem;
  padding-left: 1rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .problem-type {
  flex: 2 0 16rem;
  padding-left: 1rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .queue {
  flex: 0 0 20rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .address {
  flex: 0 0 100%; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .created,
.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .last-updated {
  flex: 0 0 50%;
  width: 100%;
  margin-top: 0.5rem;
  padding-left: 1rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t1-up.bsk-fwratio-t2-down .bsk-maplist-incident > .last-updated {
  text-align: right;
  padding-right: 1rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident {
  height: auto; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .name {
    flex: 0 0 12rem;
    max-height: 1.5rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .status {
    flex: 0 0 7rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .problem-type {
    flex: 1 0 15rem;
    padding-left: 1rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .queue {
    flex: 1 0 8rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .address {
    flex: 1 1 30rem;
    margin-top: 0; }
    .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .address > .icon {
      width: 2rem;
      height: 2rem; }
    .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .address > .text {
      height: auto; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .force-break-t3 {
    display: block; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .created,
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .last-updated {
    flex: 1 1 7rem;
    width: 100%;
    margin-top: 0.5rem;
    padding-left: 1rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t2-up.bsk-fwratio-t3-down .bsk-maplist-incident > .last-updated {
    text-align: right;
    padding-right: 1rem; }

.bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident {
  height: auto; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .name {
    flex: 1 0 10rem;
    max-height: 1.5rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .status {
    flex: 0 0 7rem;
    padding-left: 0rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .problem-type,
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .queue {
    padding-left: 1rem;
    flex: 2 0 8rem;
    margin-top: 0; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .problem-type {
    flex: 2 0 10rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .address {
    flex: 1 1 25rem;
    margin-top: 0; }
    .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .address > .icon {
      width: 2rem;
      height: 2rem;
      margin-top: 0.1rem; }
    .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .address > .text {
      height: auto; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .created,
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .last-updated {
    flex: 1 1 7rem;
    padding-left: 1rem; }
  .bsk-tile-incident-maplist.bsk-fwratio-t3-up .bsk-maplist-incident > .last-updated {
    text-align: right;
    padding-right: 1rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

#queueSummary th {
  text-align: center; }

.numTag {
  display: flex;
  justify-content: space-around; }

.numTag > div {
  border-radius: 7px;
  background-color: #eee;
  color: #333;
  width: 50px;
  text-align: center;
  padding: 0px;
  cursor: pointer; }

.queueTag > div {
  border-radius: 7px;
  background-color: #eee;
  color: #333;
  text-align: left;
  padding: 0px 10px;
  /* width: auto; */
  display: inline-block;
  cursor: pointer; }

.queueTag.itemSelected > div,
.numTag.itemSelected > div {
  background-color: #ffa600; }

.bsk-dashboard-mini.map {
  position: absolute;
  top: 10px;
  z-index: 1;
  width: 100%;
  border-radius: 0.6rem;
  overflow-x: hidden;
  overflow-y: hidden; }

.bsk-dashboard-mini.list {
  position: relative;
  width: 100%;
  margin-top: 0;
  padding-left: 0; }

.bsk-dashboard-mini .open-close-icon {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  z-index: 10;
  display: none; }
  .bsk-dashboard-mini .open-close-icon > svg {
    width: 2rem;
    height: 2rem; }

.bsk-dashboard-mini .team-name {
  padding-left: 3rem;
  padding-right: 3rem;
  line-height: 3rem; }
  .bsk-dashboard-mini .team-name > span {
    font-size: 1rem; }

.bsk-queue-summary {
  padding-left: 2rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-toast {
  opacity: 0;
  display: flex;
  flex-direction: column;
  z-index: 1500;
  background-color: #ffffff;
  border-radius: 0.3rem;
  box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.15);
  padding: 1rem;
  font-size: 1rem;
  position: absolute;
  width: 15rem;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out; }
  .bsk-toast:not(.active) {
    display: none; }
  .bsk-toast > .text {
    width: 100%;
    margin: auto;
    margin-top: 0.5rem;
    text-align: center; }
  .bsk-toast > .icon {
    width: auto;
    margin: auto; }
    .bsk-toast > .icon > svg {
      width: 2rem;
      height: 2rem; }
  .bsk-toast.fail > .icon > svg {
    color: #ff3300; }
  .bsk-toast.success > .icon > svg {
    color: #94bd15; }
  .bsk-toast.info > .icon > svg {
    color: #345c66; }
  .bsk-toast.loading > .icon > svg {
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-name: rotate;
            animation-name: rotate;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .bsk-toast.active:not(.hiding) {
    opacity: 1; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-badge {
  font-size: 1rem;
  line-height: 1.3rem;
  border-radius: 0.4rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  width: auto;
  display: inline; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-button {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 2.1rem;
  min-height: 2.1rem;
  font-size: 1rem;
  outline: unset;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .bsk-button:hover, .bsk-button.isOpen {
    border-color: #ffc000; }
  .bsk-button.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-button:not(.no-margin) {
    margin-right: 1rem; }
  .bsk-button.no-border {
    border-style: none; }
  .bsk-button.circle {
    border-radius: 50%;
    padding-left: unset;
    padding-right: unset;
    width: 3rem;
    height: 3rem; }
    .bsk-button.circle > svg {
      width: 2rem;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto; }
      .bsk-button.circle > svg.reduce-1 {
        width: 1.7rem; }
      .bsk-button.circle > svg.reduce-2 {
        width: 1.4rem; }
      .bsk-button.circle > svg.reduce-3 {
        width: 1rem; }
  .bsk-button > span,
  .bsk-button > svg {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .bsk-button:focus {
    outline: unset; }
  .bsk-button.primary {
    color: #ffffff;
    border-color: #ffc000; }
  .bsk-button.primary:not(.ghost):not(.disabled) {
    background-color: #ffc000; }
  .bsk-button:not(.disabled) {
    cursor: pointer; }
  .bsk-button.disabled {
    border-color: #e9e9e9;
    color: #aaa; }
  .bsk-button.disabled:not(.ghost) {
    background-color: #e9e9e9; }
  .bsk-button.small {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    line-height: 1.4rem;
    display: inline-block;
    min-height: 1.5rem; }
    .bsk-button.small span {
      line-height: 1.4rem; }
  .bsk-button.small.circle {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 1px;
    max-width: 1px; }
    .bsk-button.small.circle > svg {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  .bsk-button:last-of-type:not(.keep-mg-right) {
    margin-right: 0; }
  .bsk-button.no-margin-bottom {
    margin-bottom: 0; }

.bsk-platform-desktop .bsk-button:hover:not(.disabled):not(.primary):not(.ghost) > span,
.bsk-platform-desktop .bsk-button:hover:not(.disabled):not(.primary):not(.ghost) > svg {
  color: #bf9000; }

.bsk-platform-desktop .bsk-button.primary:hover:not(.ghost):not(.disabled) {
  background-color: #bf9000;
  border-color: #bf9000; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-checkbox {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  font-size: 1.2rem;
  width: 1.8rem;
  line-height: 1.8rem;
  min-height: 1.8rem;
  position: relative;
  cursor: pointer; }
  .bsk-checkbox:hover, .bsk-checkbox.isOpen {
    border-color: #ffc000; }
  .bsk-checkbox.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-checkbox .bsk-checkbox-active {
    width: 70%;
    height: 70%;
    margin: auto;
    opacity: 0;
    line-height: 1rem;
    padding-top: 0.18rem; }
    .bsk-checkbox .bsk-checkbox-active svg {
      margin: auto;
      color: #ffffff;
      width: 100%;
      height: 100%; }
  .bsk-checkbox.active {
    background-color: #ffc000;
    border-color: #ffc000; }
    .bsk-checkbox.active .bsk-checkbox-active {
      opacity: 1; }
  .bsk-checkbox.disabled {
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    cursor: unset; }
    .bsk-checkbox.disabled .bsk-checkbox-effect {
      display: none; }
    .bsk-checkbox.disabled .bsk-checkbox-active > svg {
      color: #aaa; }
  .bsk-checkbox.active > .bsk-checkbox-effect {
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.3rem;
    border-color: #ffc000;
    border-width: 0.5rem;
    border-style: solid;
    opacity: 0.5;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8; }
  100% {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-image-main {
  line-height: 40px;
  min-height: 40px;
  position: relative;
  padding-right: 2px; }
  .bsk-image-main > input {
    outline: unset;
    line-height: 29px;
    width: 100%;
    border: unset; }
  .bsk-image-main:hover, .bsk-image-main.isOpen {
    border-color: #ffc000; }
  .bsk-image-main.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-image-main.showOnly .bsk-image-content-close-icon {
    display: none; }

.bsk-image-main-container {
  display: block; }
  .bsk-image-main-container ul {
    list-style-type: none;
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    margin-bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    line-height: normal;
    line-height: initial; }
    .bsk-image-main-container ul li {
      position: relative;
      float: left;
      box-sizing: content-box; }

.bsk-image-main-entry-content {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  min-width: 12rem;
  width: 12rem;
  max-width: 12rem;
  min-height: 12rem;
  height: 12rem;
  max-height: 12rem;
  line-height: 29px;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  padding: 0.5rem;
  position: relative;
  display: flex;
  flex-direction: row;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box; }
  .bsk-image-main-entry-content:hover, .bsk-image-main-entry-content.isOpen {
    border-color: #ffc000; }
  .bsk-image-main-entry-content.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-image-main-entry-content .entry-select-item {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden; }
  .bsk-image-main-entry-content .entry-select-multiple-icon {
    position: absolute;
    right: 5px;
    bottom: 5px; }
  .bsk-image-main-entry-content .bsk-image-add {
    margin: auto;
    line-height: unset;
    flex: 0 0 100%;
    position: relative;
    height: 2rem;
    width: 2rem; }
  .bsk-image-main-entry-content .bsk-image-button-add {
    height: 1px;
    max-height: 1px;
    width: 1px;
    max-width: 1px;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0; }
  .bsk-image-main-entry-content .bsk-image-content-image {
    width: 100%;
    object-fit: contain; }
  .bsk-image-main-entry-content .bsk-image-content-close-icon {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #ccc;
    width: 1.5rem;
    height: 1.5rem; }
    .bsk-image-main-entry-content .bsk-image-content-close-icon path {
      fill: #ccc; }
    .bsk-image-main-entry-content .bsk-image-content-close-icon .insideWhite {
      fill: #fff; }

.bsk-image-main.no-border .bsk-image-main-entry-content {
  border-style: none; }

.bsk-screen-small .bsk-image-main {
  padding-left: 0px; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-input-main {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  font-size: 1rem;
  position: relative;
  padding-left: 20px;
  padding-right: 2px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  display: flex;
  flex-direction: row;
  background-color: #ffffff; }
  .bsk-input-main:hover, .bsk-input-main.isOpen {
    border-color: #ffc000; }
  .bsk-input-main.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-input-main.no-border {
    border-style: none; }
  .bsk-input-main.isOpen.no-border {
    box-shadow: unset; }
  .bsk-input-main > input {
    outline: unset;
    line-height: 2.2rem;
    border: unset;
    flex: 1 1 auto;
    margin-right: 1rem;
    width: 100%;
    display: block; }
    .bsk-input-main > input::-webkit-input-placeholder {
      color: #aaa; }
    .bsk-input-main > input::-moz-placeholder {
      color: #aaa; }
    .bsk-input-main > input:-ms-input-placeholder {
      color: #aaa; }
    .bsk-input-main > input::-ms-input-placeholder {
      color: #aaa; }
    .bsk-input-main > input::placeholder {
      color: #aaa; }
  .bsk-input-main > textarea {
    outline: unset;
    line-height: 2.2rem;
    border: unset;
    width: 100%;
    flex: 1 1 auto;
    display: block;
    margin-bottom: 8px;
    margin-right: 0.2rem;
    padding-left: 0px; }
    .bsk-input-main > textarea::-webkit-input-placeholder {
      color: #aaa; }
    .bsk-input-main > textarea::-moz-placeholder {
      color: #aaa; }
    .bsk-input-main > textarea:-ms-input-placeholder {
      color: #aaa; }
    .bsk-input-main > textarea::-ms-input-placeholder {
      color: #aaa; }
    .bsk-input-main > textarea::placeholder {
      color: #aaa; }
  .bsk-input-main .bsk-input-search {
    width: 5rem;
    background-color: #ffc000;
    margin-top: -0.2rem;
    margin-bottom: -0.2rem;
    margin-right: -2px;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    text-align: center;
    line-height: 1rem;
    padding-top: 0.6rem; }
    .bsk-input-main .bsk-input-search svg {
      color: #ffffff;
      width: 1.5rem;
      height: 1.5rem;
      margin: auto; }
  .bsk-input-main.disabled .bsk-input-search {
    background-color: #e9e9e9; }
  .bsk-input-main.disabled:hover {
    border-color: #e9e9e9; }
  .bsk-input-main.disabled {
    background-color: #e9e9e9;
    border-color: #e9e9e9; }
    .bsk-input-main.disabled .bsk-input-search {
      border-left-color: #ffffff;
      border-left-width: 1px;
      border-left-style: solid; }
    .bsk-input-main.disabled > input,
    .bsk-input-main.disabled > textarea {
      background-color: #e9e9e9;
      color: #aaa; }

.input-text-small-lh .bsk-input-main > textarea {
  line-height: 1.5rem; }

.bsk-screen-small .bsk-input-main {
  padding-left: 10px; }

.bsk-page-title {
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .bsk-page-title.shrink,
  .bsk-page-title.shrink > .content {
    padding: 0; }
  .bsk-page-title:not(.no-mb) {
    margin-bottom: 3rem; }
  .bsk-page-title > .title {
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem;
    padding-bottom: 1rem; }
  .bsk-page-title.no-title > .title {
    display: none; }
  .bsk-page-title > .content {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%; }
  .bsk-page-title.fit-height {
    display: flex;
    flex-direction: column; }
    .bsk-page-title.fit-height > .title {
      flex: 0 0 auto; }
    .bsk-page-title.fit-height > .content {
      flex: 1 1 auto; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-pop {
  display: inline-block;
  position: relative; }
  .bsk-pop:not(.active) > .icon {
    display: none; }
  .bsk-pop > .icon {
    position: absolute;
    width: 1rem;
    height: 1rem; }
    .bsk-pop > .icon > .shift {
      top: -50%;
      left: -50%;
      position: absolute;
      -webkit-transform-origin: center;
              transform-origin: center;
      height: 1rem;
      line-height: 1rem;
      z-index: 100; }
      .bsk-pop > .icon > .shift > svg {
        width: 1rem;
        height: 1rem;
        color: #e9e9e9; }
    .bsk-pop > .icon > .content {
      z-index: 200;
      display: flex;
      position: absolute;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      right: 1.2rem;
      bottom: 1rem;
      background-color: #fff;
      z-index: 200;
      flex: 0 0 auto;
      position: relative;
      box-shadow: 0rem 0rem 0.3rem 0.1rem rgba(0, 0, 0, 0.15);
      border-radius: 0.5rem; }
    .bsk-pop > .icon.to-up > .content {
      -webkit-transform: translateY(0%) translateX(-50%);
              transform: translateY(0%) translateX(-50%);
      left: 0;
      top: 0.3rem; }
    .bsk-pop > .icon.to-down > .shift {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .bsk-pop > .icon.to-down > .content {
      -webkit-transform: translateX(-50%) translateY(-100%);
              transform: translateX(-50%) translateY(-100%);
      left: -0rem;
      top: -0.3rem; }
    .bsk-pop > .icon.to-left > .shift {
      -webkit-transform: rotate(270deg);
              transform: rotate(270deg); }
    .bsk-pop > .icon.to-left > .content {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 0.3rem;
      top: 0rem; }
    .bsk-pop > .icon.to-right > .shift {
      -webkit-transform: rotate(90deg);
              transform: rotate(90deg); }
    .bsk-pop > .icon.to-right > .content {
      -webkit-transform: translateX(-100%) translateY(-50%);
              transform: translateX(-100%) translateY(-50%);
      left: -0.3rem;
      top: 0; }

.bsk-confirm {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 1rem;
  padding-bottom: 0.1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto; }
  .bsk-confirm .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row; }
    .bsk-confirm .content .icon {
      flex: 0 0 auto;
      padding-right: 1rem; }
      .bsk-confirm .content .icon > svg {
        width: 1.5rem;
        height: 1.5rem; }
      .bsk-confirm .content .icon.friendly > svg,
      .bsk-confirm .content .icon.driveTo > svg {
        color: #345c66; }
      .bsk-confirm .content .icon.warning > svg {
        color: #ff3300; }
    .bsk-confirm .content .text {
      flex: 1 1 auto; }
  .bsk-confirm .footer {
    flex: 0 0 auto;
    margin-top: 1rem;
    max-width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly; }
    .bsk-confirm .footer > * {
      flex: 0 0 auto; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-radio-group {
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-right-style: none;
  cursor: pointer; }
  .bsk-radio-group > .bsk-radio-button:not(:last-of-type) {
    border-right-style: none; }
  .bsk-radio-group.no-margin-bottom {
    margin-bottom: 0; }

.bsk-radio-button {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 2.1rem;
  min-height: 2.1rem;
  font-size: 1rem;
  line-height: unset;
  border-radius: 0.2rem;
  outline: unset;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-style: none;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  .bsk-radio-button:hover, .bsk-radio-button.isOpen {
    border-color: #ffc000; }
  .bsk-radio-button.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-radio-button:last-of-type {
    margin-right: 0; }
  .bsk-radio-button.selected:not(.disabled) {
    color: #000000;
    background-color: #ffe699; }
  .bsk-radio-button:not(.selected):not(.disabled) {
    color: #000000;
    background-color: #e8e8e8; }
  .bsk-radio-button.selected.disabled:not(.ghost) {
    background-color: #ffffff; }
  .bsk-radio-button:hover + .bsk-radio-button:not(.disabled) {
    border-left-color: #ffc000; }
  .bsk-radio-button.circle {
    border-radius: 50%;
    padding-left: unset;
    padding-right: unset;
    width: 3rem;
    height: 3rem; }
    .bsk-radio-button.circle > svg {
      width: 2rem;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto; }
      .bsk-radio-button.circle > svg.reduce-1 {
        width: 1.7rem; }
      .bsk-radio-button.circle > svg.reduce-2 {
        width: 1.4rem; }
      .bsk-radio-button.circle > svg.reduce-3 {
        width: 1rem; }
  .bsk-radio-button > span,
  .bsk-radio-button > svg {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .bsk-radio-button:focus {
    outline: unset; }
  .bsk-radio-button:hover:not(.disabled):not(.primary):not(.ghost) > span,
  .bsk-radio-button:hover:not(.disabled):not(.primary):not(.ghost) > svg {
    color: #bf9000; }
  .bsk-radio-button.primary {
    color: #ffffff;
    border-color: #ffc000; }
  .bsk-radio-button.primary:not(.ghost):not(.disabled) {
    background-color: #ffc000; }
    .bsk-radio-button.primary:not(.ghost):not(.disabled):hover {
      background-color: #bf9000;
      border-color: #bf9000; }
  .bsk-radio-button:not(.disabled) {
    cursor: pointer; }
  .bsk-radio-button.disabled {
    border-color: #e9e9e9;
    color: #aaa; }
  .bsk-radio-button.disabled:not(.ghost) {
    background-color: #e9e9e9; }
  .bsk-radio-button.small {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    line-height: 1.4rem;
    display: inline-block;
    min-height: 2.1rem; }
    .bsk-radio-button.small span {
      line-height: 1.4rem; }
  .bsk-radio-button:last-of-type {
    margin-right: 0; }
  .bsk-radio-button.no-margin-bottom {
    margin-bottom: 0; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-select-detached-modal,
.bsk-select-detached-background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }

.bsk-screen-small .bsk-select-detached-background {
  background-color: rgba(51, 51, 51, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }

.bsk-screen-normal .bsk-select-detached-background {
  background-color: rgba(255, 255, 255, 0); }

.bsk-select-detached-foreground {
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  cursor: pointer; }
  .bsk-select-detached-foreground > ul {
    list-style-type: none;
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    margin-bottom: 0px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch; }
    .bsk-select-detached-foreground > ul > li {
      line-height: 3rem;
      min-height: 3rem;
      font-size: 1.2rem;
      font-size: 1rem;
      padding-left: 40px;
      padding-right: 10px;
      -webkit-transition: background-color 0.2s ease;
      transition: background-color 0.2s ease;
      position: relative; }
      .bsk-select-detached-foreground > ul > li .label {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .bsk-select-detached-foreground > ul > li:hover, .bsk-select-detached-foreground > ul > li.active-item:hover {
        background-color: rgba(255, 192, 0, 0.2); }
      .bsk-select-detached-foreground > ul > li.active-item {
        background-color: rgba(255, 192, 0, 0.1); }
      .bsk-select-detached-foreground > ul > li.active-item .label {
        font-weight: 600; }

.bsk-screen-small .bsk-select-detached-foreground {
  padding-top: 40px;
  flex: 0 0 80%; }
  .bsk-screen-small .bsk-select-detached-foreground > ul > li {
    padding-left: 5px;
    padding-right: 5px; }
    .bsk-screen-small .bsk-select-detached-foreground > ul > li:hover {
      background-color: unset; }
    .bsk-screen-small .bsk-select-detached-foreground > ul > li.active-item:hover {
      background-color: rgba(255, 192, 0, 0.1); }

.bsk-screen-normal .bsk-select-detached-foreground {
  box-shadow: 0 2px 8px rgba(191, 144, 0, 0.2);
  justify-content: left; }

.bsk-select-detached-close-icon {
  position: absolute;
  left: -9999px; }

.bsk-screen-small .bsk-select-detached-close-icon {
  right: 10px;
  top: 5px;
  left: auto; }

.select-single > .bsk-select-detached-foreground > ul > li > .arrow {
  display: none; }

.select-multiple > .bsk-select-detached-foreground > ul > li .label {
  padding-right: 30px; }

.select-multiple > .bsk-select-detached-foreground > ul > li .arrow {
  position: absolute;
  right: 10px;
  bottom: 0px; }
  .select-multiple > .bsk-select-detached-foreground > ul > li .arrow svg {
    width: 1.5rem;
    height: 1.5rem; }

.bsk-select-main {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 3rem;
  min-height: 3rem;
  position: relative; }
  .bsk-select-main:hover, .bsk-select-main.isOpen {
    border-color: #ffc000; }
  .bsk-select-main.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-select-main.no-frame {
    border-style: none; }
    .bsk-select-main.no-frame > .bsk-select-main-container {
      padding-left: 0;
      padding-right: 0; }
  .bsk-select-main.no-border {
    border-style: none; }
    .bsk-select-main.no-border.isOpen {
      box-shadow: unset; }
  .bsk-select-main.cells-white .bsk-select-main-entry > .bsk-select-main-entry-content {
    background-color: #ffffff; }
  .bsk-select-main.disabled {
    background-color: #e9e9e9;
    color: #aaa;
    border-color: #e9e9e9; }
  .bsk-select-main .arrow {
    position: absolute;
    right: 10px;
    bottom: -6%; }
    .bsk-select-main .arrow svg {
      -webkit-transition: -webkit-transform 0.3s ease;
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease;
      width: 1.5rem;
      height: 1.5rem; }

.bsk-screen-small .bsk-select-main {
  width: auto; }

.select-multiple.bsk-select-main > .arrow {
  display: none; }

.isOpen.bsk-select-main .arrow svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.select-multiple .bsk-select-main-entry-content {
  line-height: 2rem;
  margin-bottom: 0.3rem;
  margin-right: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 1.6rem;
  border-radius: 0.2rem;
  border: 1px solid #d9d9d9;
  background-color: #e9e9e9;
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  max-width: 99%;
  font-size: 1rem; }
  .select-multiple .bsk-select-main-entry-content .entry-select-item {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden; }
  .select-multiple .bsk-select-main-entry-content .entry-select-multiple-icon {
    position: absolute;
    right: 0.2rem;
    top: -0.2rem;
    color: #afacab; }

.bsk-select-main.disabled .bsk-select-main-entry-content {
  border-color: #d9d9d9;
  padding-right: 0.3rem; }
  .bsk-select-main.disabled .bsk-select-main-entry-content .entry-select-multiple-icon {
    display: none; }

.bsk-select-main.disabled > .arrow {
  display: none; }

.select-single .bsk-select-main-entry-content {
  line-height: 2rem;
  margin-bottom: 3px;
  margin-right: 3px;
  position: relative;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
  max-width: 99%;
  font-size: 1rem; }
  .select-single .bsk-select-main-entry-content .entry-select-item {
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden; }
  .select-single .bsk-select-main-entry-content .entry-select-multiple-icon {
    display: none; }

.bsk-select-main-container {
  line-height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 20px;
  padding-right: 10px;
  display: block; }
  .bsk-select-main-container ul {
    padding-top: 0.3rem;
    list-style-type: none;
    list-style: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    margin-bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    line-height: 1.6rem; }
    .bsk-select-main-container ul li {
      position: relative;
      float: left;
      box-sizing: content-box; }

.select-multiple .bsk-select-main-entry {
  max-width: 60%; }

.select-single .bsk-select-main-entry {
  max-width: 90%; }

.bsk-screen-small .bsk-select-main-container {
  padding-left: 10px; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

#mainAppFooter {
  flex: 0 0 1rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  touch-action: none;
  line-height: 1rem; }

#mainAppFooterLeft,
#mainAppFooterRight {
  flex: 1 1 50%;
  background-color: #ffc000;
  text-align: center; }
  #mainAppFooterLeft .ft-container,
  #mainAppFooterRight .ft-container {
    margin: auto;
    display: inline-flex;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    line-height: 1.8rem; }
    #mainAppFooterLeft .ft-container.unselected,
    #mainAppFooterRight .ft-container.unselected {
      color: #bf9000; }
    #mainAppFooterLeft .ft-container.selected,
    #mainAppFooterRight .ft-container.selected {
      color: #ffffff; }
    #mainAppFooterLeft .ft-container .ft-text,
    #mainAppFooterRight .ft-container .ft-text {
      padding-top: 2px;
      font-size: 1.4rem;
      margin-left: 8px;
      font-weight: 600; }
    #mainAppFooterLeft .ft-container .ft-icon > svg,
    #mainAppFooterRight .ft-container .ft-icon > svg {
      width: 2rem;
      height: 2rem; }

.bsk-height-t1-down #mainAppFooter .ft-container {
  padding-top: 0.1rem;
  padding-bottom: 0; }
  .bsk-height-t1-down #mainAppFooter .ft-container .ft-icon {
    line-height: 0.8rem; }
  .bsk-height-t1-down #mainAppFooter .ft-container .ft-text {
    padding-top: 0.2rem;
    line-height: unset; }

#mainAppFooterLeft {
  padding-right: 2px; }

#mainAppFooterRight {
  padding-left: 2px; }

.ft-plus {
  flex: 0 0 2px;
  max-width: 2px;
  z-index: 800;
  position: relative; }
  .ft-plus > .svg-container {
    width: auto;
    height: auto;
    position: absolute;
    top: -2rem;
    left: -2rem; }
    .ft-plus > .svg-container svg {
      width: 4rem;
      height: 4rem;
      position: relative; }
  .ft-plus.not-ready .cirIn {
    fill: #bf9000; }
  .ft-plus .cirOut {
    fill: #ffffff; }
  .ft-plus .cirIn {
    fill: #ffc000; }
  .ft-plus .line {
    fill: #ffffff; }

.insideWhite {
  fill: #ffffff; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-should-connect {
  display: flex;
  flex-direction: column;
  font-size: 1rem; }
  .bsk-should-connect > .message {
    flex: 0 0 auto;
    padding-top: 2rem;
    text-align: center;
    font-size: 1.2rem; }
  .bsk-should-connect > .footer {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #00b0f1;
    cursor: pointer; }
    .bsk-should-connect > .footer > .icon {
      flex: 0 0 auto; }
      .bsk-should-connect > .footer > .icon > svg {
        width: 2rem;
        height: 2rem; }
    .bsk-should-connect > .footer > .text {
      padding-left: 2rem;
      flex: 0 0 auto;
      font-size: 1.5rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  display: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-name: modalIn;
          animation-name: modalIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }
  .bsk-modal.visible {
    display: block; }
    .bsk-modal.visible > .foreground {
      -webkit-animation-duration: 0.3s;
              animation-duration: 0.3s;
      -webkit-animation-name: modalInFg;
              animation-name: modalInFg;
      -webkit-animation-play-state: running;
              animation-play-state: running;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both; }
  .bsk-modal.fade {
    -webkit-animation-name: modalOut;
            animation-name: modalOut;
    -webkit-animation-play-state: running;
            animation-play-state: running; }
    .bsk-modal.fade > .foreground {
      -webkit-animation-name: modalOutFg;
              animation-name: modalOutFg;
      -webkit-animation-play-state: running;
              animation-play-state: running; }
  .bsk-modal > .foreground {
    width: 35rem;
    background-color: #ffffff;
    margin: auto;
    position: relative;
    top: 10%;
    border-radius: 0.6rem;
    overflow-x: none;
    font-size: 1rem;
    display: flex;
    flex-direction: column; }
    .bsk-modal > .foreground > .close {
      position: absolute;
      top: 10px;
      right: 10px; }
      .bsk-modal > .foreground > .close > svg {
        width: 2rem;
        height: 2rem; }
    .bsk-modal > .foreground > .title {
      flex: 0 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.2rem;
      font-weight: 700;
      margin-left: 1rem;
      margin-right: 3rem;
      border-bottom: 1px solid #e9e9e9; }
    .bsk-modal > .foreground > .content {
      flex: 1 1 auto;
      overflow-y: none;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem; }
      .bsk-modal > .foreground > .content.no-title {
        margin-top: 2.5rem; }
      .bsk-modal > .foreground > .content.no-title.no-mt {
        margin-top: 0.5rem;
        overflow-y: auto;
        display: block;
        max-height: 100%; }
    .bsk-modal > .foreground > .footer {
      flex: 0 0 auto;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-left: 1rem;
      padding-left: 2rem;
      margin-right: 1rem;
      border-top: 1px solid #e9e9e9;
      background-color: unset; }
      .bsk-modal > .foreground > .footer.buttons {
        width: auto;
        display: flex;
        padding-bottom: 0; }
        .bsk-modal > .foreground > .footer.buttons > button {
          flex: 0 0 auto; }
        .bsk-modal > .foreground > .footer.buttons > .push {
          flex: 1 1 100%; }

.bsk-modal.blue-style.visible {
  background-color: rgba(0, 0, 0, 0); }

.bsk-modal.blue-style > .foreground {
  border: 1px solid #00b0f1; }

.bsk-modal.blue-style .insideWhite {
  fill: #ffffff; }

.bsk-modal.blue-style .close > svg {
  fill: #00b0f1; }

.bsk-std-blue-modal .item {
  margin-top: 0.5rem; }
  .bsk-std-blue-modal .item:first-of-type {
    margin-top: 2rem; }

.bsk-std-blue-modal .phone {
  margin-top: 1rem;
  color: #00b0f1;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .bsk-std-blue-modal .phone .icon {
    width: 2rem;
    height: 2rem; }
  .bsk-std-blue-modal .phone .spacer {
    width: 1rem; }
  .bsk-std-blue-modal .phone .text {
    font-size: 1.3rem; }

@-webkit-keyframes modalIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes modalIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes modalInFg {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes modalInFg {
  0% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes modalOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes modalOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes modalOutFg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); } }

@keyframes modalOutFg {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0.6);
            transform: scale(0.6); } }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-queues-list {
  padding: 1rem;
  max-width: 20rem;
  position: relative; }
  .bsk-queues-list > .title {
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
    color: #00b0f1;
    padding-right: 2rem; }
  .bsk-queues-list > .icon {
    position: absolute;
    right: 0.7rem;
    top: 1.2rem; }
    .bsk-queues-list > .icon > svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: #00b0f1; }
  .bsk-queues-list > .list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0.5rem; }
    .bsk-queues-list > .list > .bsk-queues-list-item {
      flex: 0 0 auto;
      display: flex;
      flex-direction: row;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      padding-left: 0.5rem;
      border-radius: 0.3rem;
      align-items: center; }
      .bsk-queues-list > .list > .bsk-queues-list-item:hover {
        background-color: #ffc000; }
      .bsk-queues-list > .list > .bsk-queues-list-item > svg {
        flex: 0 0 auto;
        width: 1.5rem;
        height: 2rem; }
      .bsk-queues-list > .list > .bsk-queues-list-item > .text {
        flex: 0 0 auto;
        padding-left: 1rem;
        font-size: 1rem; }

.bsk-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 1px 7px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .bsk-tag.has-color {
    color: #ffffff;
    border-color: transparent; }
  .bsk-tag:hover {
    opacity: 0.85; }
  .bsk-tag.no-margin {
    margin-right: 0; }
  .bsk-tag.big {
    padding: 0.3rem 1.5rem;
    border-radius: 1rem;
    text-align: center; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-float-button-top {
  position: absolute;
  width: 100%;
  top: 0; }
  .bsk-float-button-top > div {
    position: absolute;
    display: flex; }
    .bsk-float-button-top > div > .bsk-float-button {
      flex: 0 0 auto;
      background-color: rgba(255, 255, 255, 0.8);
      padding: 0.3rem;
      color: rgba(0, 0, 0, 0.5);
      border-radius: 0.3rem;
      margin-right: 0.5rem; }
      .bsk-float-button-top > div > .bsk-float-button:first-of-type {
        margin-top: 0; }
      .bsk-float-button-top > div > .bsk-float-button svg {
        display: block;
        width: 2rem;
        height: 2rem; }
  .bsk-float-button-top.rightBar > div {
    top: 4rem;
    right: 0.5rem;
    flex-direction: column; }
    .bsk-float-button-top.rightBar > div > .bsk-float-button {
      margin-top: 0.5rem; }
  .bsk-float-button-top.simpleClose {
    z-index: 10; }
    .bsk-float-button-top.simpleClose > div {
      top: 0;
      right: 0;
      flex-direction: column; }
      .bsk-float-button-top.simpleClose > div > .bsk-float-button {
        margin-top: 0; }
  .bsk-float-button-top.infoWindow > div {
    top: 0;
    right: 0; }
    .bsk-float-button-top.infoWindow > div > .bsk-float-button {
      margin-right: 0; }
  .bsk-float-button-top.small > div > .bsk-float-button > svg {
    width: 1.4rem;
    height: 1.4rem; }

.bsk-map-markers-loading {
  width: 100%;
  height: 100%; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.rb-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative; }
  .rb-main .header {
    flex: 0 0 50px; }
  .rb-main .tile-manager {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: absolute;
    overflow-y: visible;
    overflow-x: visible;
    width: 100%; }
    .rb-main .tile-manager.interestScroll {
      -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
      transition: left 0.3s ease-out, width 0.3s ease-out; }
      .rb-main .tile-manager.interestScroll > .rb-col {
        -webkit-transition: flex-basis 0.3s ease-out, max-width 0.3s ease-out;
        transition: flex-basis 0.3s ease-out, max-width 0.3s ease-out; }
    .rb-main .tile-manager .rb-col {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 100%;
      height: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      position: relative; }
      .rb-main .tile-manager .rb-col .tile {
        width: 100%;
        flex: 1 1 auto;
        margin-bottom: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative; }
        .rb-main .tile-manager .rb-col .tile .tile-content-split {
          flex: 1 1 50%;
          margin-right: 5px;
          overflow-y: auto;
          overflow-x: hidden; }
          .rb-main .tile-manager .rb-col .tile .tile-content-split.short {
            flex: 0 0 auto; }
          .rb-main .tile-manager .rb-col .tile .tile-content-split .tile-content {
            font-size: 1.6rem; }
            .rb-main .tile-manager .rb-col .tile .tile-content-split .tile-content.active {
              background-color: rgba(255, 255, 255, 0.5); }
          .rb-main .tile-manager .rb-col .tile .tile-content-split:last-of-type {
            margin-right: 0px; }
          .rb-main .tile-manager .rb-col .tile .tile-content-split .tile-content {
            margin-left: 1rem; }
          .rb-main .tile-manager .rb-col .tile .tile-content-split.fit {
            display: flex;
            flex-direction: column; }
            .rb-main .tile-manager .rb-col .tile .tile-content-split.fit > div {
              flex: 1 1 auto; }
        .rb-main .tile-manager .rb-col .tile:last-of-type {
          margin-bottom: 0; }
        .rb-main .tile-manager .rb-col .tile.smaller {
          flex-grow: 0;
          height: unset; }

.rb-col.hide-header .tile-title {
  display: none; }

.demo-lr {
  padding: 1rem 0.5rem;
  overflow-y: auto;
  white-space: wrap;
  font-size: 1.2rem;
  width: auto; }

.test-witness-div {
  width: 100%;
  height: 100%; }
  .test-witness-div .test-container {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    .test-witness-div .test-container.mx-width {
      max-width: unset; }

.bsk-tile-incident-dashboard {
  order: 0; }

.bsk-tile-incident-maplist {
  order: 1; }

.bsk-tile-incident-view {
  order: 2; }

.bsk-tile-incident-image {
  order: 3; }

.bsk-tile-incident-createedit {
  order: 4; }

.bsk-tile-incident-unused {
  order: 5; }

.bsk-tile-generic-1 {
  order: 6; }

.bsk-tile-generic-2 {
  order: 7; }

.bsk-tile-generic-3 {
  order: 8; }

.tile-manager.editing .bsk-tile-incident-createedit {
  order: 3; }

.tile-manager.editing .bsk-tile-incident-image {
  order: 4; }

.tile-manager.creating .bsk-tile-incident-createedit,
.tile-manager.sub-creating .bsk-tile-incident-createedit {
  order: 2; }

.tile-manager.creating .bsk-tile-incident-image,
.tile-manager.sub-creating .bsk-tile-incident-image {
  order: 3; }

.tile-manager.creating .bsk-tile-incident-view,
.tile-manager.sub-creating .bsk-tile-incident-view {
  order: 4; }

body .rmc-drawer-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out;
  transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-image-view-container {
  margin: auto;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%; }
  .bsk-image-view-container.loaded .bsk-image-view-image {
    position: absolute; }

.bsk-image-view-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-width: none; }

.bsk-image-view-component {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  text-align: center; }

.bsk-image-icons-container {
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 30;
  display: flex;
  flex-direction: column; }
  .bsk-image-icons-container .bsk-image-view-one-one-icon {
    flex: 1 0 auto;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 5px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin-top: 0.5rem; }
    .bsk-image-icons-container .bsk-image-view-one-one-icon:first-of-type {
      margin-top: 0; }
    .bsk-image-icons-container .bsk-image-view-one-one-icon svg {
      display: block;
      width: 2rem;
      height: 2rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.numTag {
  display: flex;
  justify-content: space-around; }

.numTag > div {
  border-radius: 7px;
  background-color: #eee;
  color: #333;
  width: 50px;
  text-align: center;
  padding: 0px;
  cursor: pointer; }

.queueTag > div {
  border-radius: 7px;
  background-color: #eee;
  color: #333;
  text-align: left;
  padding: 0px 10px;
  /* width: auto; */
  display: inline-block;
  cursor: pointer; }

.queueTag.itemSelected > div,
.numTag.itemSelected > div {
  background-color: #ffc000; }

.bsk-dashboard-new {
  background-color: #f6f6f6;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden; }
  .bsk-dashboard-new .team-name {
    padding-left: 3rem;
    padding-right: 1rem;
    line-height: 3rem; }
    .bsk-dashboard-new .team-name > span {
      font-size: 1rem; }
  .bsk-dashboard-new > .bsk-page-title > .content > div {
    border-radius: 0.6rem;
    background-color: #ffffff;
    padding: 0.5rem; }

.bsk-dashboard-new > .bsk-page-title > .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .bsk-dashboard-new > .bsk-page-title > .content > .filters-active {
    order: 1;
    flex: 1 1 100%;
    margin-bottom: 1rem; }
  .bsk-dashboard-new > .bsk-page-title > .content > .dashboard {
    order: 2;
    flex: 1 0 30rem; }
  .bsk-dashboard-new > .bsk-page-title > .content > .filters-add {
    margin-left: 1rem;
    order: 3;
    flex: 1 0 17rem; }
  .bsk-dashboard-new > .bsk-page-title > .content > .charts {
    margin-top: 1rem;
    flex: 1 1 100%;
    order: 4; }

.bsk-fwratio-t2-down .bsk-dashboard-new > .bsk-page-title > .content > .dashboard,
.bsk-fwratio-t2-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-active,
.bsk-fwratio-t2-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-add,
.bsk-fwratio-t2-down .bsk-dashboard-new > .bsk-page-title > .content > .charts {
  margin-top: 1rem; }

.bsk-fwratio-t2-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-add {
  margin-left: 0;
  flex: 1 0 18rem; }

.bsk-fwratio-t1-down .bsk-dashboard-new > .bsk-page-title > .content > .dashboard,
.bsk-fwratio-t1-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-active,
.bsk-fwratio-t1-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-add,
.bsk-fwratio-t1-down .bsk-dashboard-new > .bsk-page-title > .content > .charts {
  margin-top: 1rem;
  flex: 1 1 10rem; }

.bsk-fwratio-t1-down .bsk-dashboard-new > .bsk-page-title > .content > .filters-add {
  margin-left: 0;
  flex: 1 0 18rem; }

.bsk-queue-summary-new > .queue-line {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .bsk-queue-summary-new > .queue-line > .team {
    flex: 1 1 15rem;
    display: flex;
    flex-direction: row; }
    .bsk-queue-summary-new > .queue-line > .team > .icon {
      flex: 0 0 2rem;
      height: 1.5rem;
      padding-right: 0.5rem; }
    .bsk-queue-summary-new > .queue-line > .team > .text {
      flex: 1 1 5rem;
      float: left;
      padding: 0.3rem 0.5rem;
      border-radius: 0.3rem; }
    .bsk-queue-summary-new > .queue-line > .team.main-selected > .text {
      background-color: #ffc000;
      color: #ffffff; }
    .bsk-queue-summary-new > .queue-line > .team.sub-selected > .text {
      background-color: #ffe699; }
  .bsk-queue-summary-new > .queue-line > .status-group {
    flex: 1 1 25rem;
    display: flex;
    flex-direction: row; }
    .bsk-queue-summary-new > .queue-line > .status-group > .status {
      flex: 1 1 1rem;
      text-align: center;
      width: 100%; }
      .bsk-queue-summary-new > .queue-line > .status-group > .status > div {
        margin: auto;
        width: 3rem;
        border-radius: 0.3rem; }
      .bsk-queue-summary-new > .queue-line > .status-group > .status.main-selected > div {
        background-color: #ffc000;
        color: #ffffff; }
      .bsk-queue-summary-new > .queue-line > .status-group > .status.sub-selected > div {
        background-color: #ffe699; }
  .bsk-queue-summary-new > .queue-line.data {
    margin-top: 0.5rem; }
  .bsk-queue-summary-new > .queue-line.header > .status-group > .status > div {
    width: auto;
    height: 100%; }
  .bsk-queue-summary-new > .queue-line:not(.header) > .status-group > .status > div {
    border-radius: 1rem;
    padding: 0.3rem 0.5rem; }
  .bsk-queue-summary-new > .queue-line:not(.header) > .status-group > .status:not(.main-selected) > div {
    background-color: #fafafa; }

.bsk-fwratio-t2-down .bsk-queue-summary-new > .queue-line > .team {
  flex: 1 1 12rem; }

.bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line {
  flex-direction: column; }
  .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line > .team {
    flex: 0 0 1rem;
    font-size: 0.8rem; }
    .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line > .team > .text {
      background-color: unset; }
    .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line > .team.main-selected > .text {
      background-color: #ffc000; }
  .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line > .status-group {
    margin-top: 0.5rem;
    flex: 0 0 1rem; }
    .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line > .status-group > .status > div {
      width: 3rem; }
  .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line.header > .status-group > .status > div {
    width: auto;
    background-color: unset; }
  .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line.header > .status-group > .status.main-selected > div {
    background-color: #ffc000; }
  .bsk-fwratio-t2-down.bsk-fwratio-t1-down .bsk-queue-summary-new > .queue-line.header .status {
    font-size: 0.8rem; }

.bsk-filter-split {
  display: flex;
  flex-direction: row;
  margin-top: 0.2rem; }
  .bsk-filter-split > .left,
  .bsk-filter-split > .right {
    flex: 1 1 1rem; }

.bsk-fwratio-t1-down .no-small {
  display: none; }



.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-button-icon {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  font-size: 1.2rem;
  outline: unset;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  place-content: baseline;
  text-align: center;
  display: inline-flex; }
  .bsk-button-icon:hover, .bsk-button-icon.isOpen {
    border-color: #ffc000; }
  .bsk-button-icon.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-button-icon > div {
    display: flex;
    flex-direction: row;
    width: auto;
    margin: auto;
    display: inline-flex; }
    .bsk-button-icon > div > span {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 2rem;
      margin-left: 1rem;
      font-size: 1.8rem; }
    .bsk-button-icon > div > span,
    .bsk-button-icon > div > svg {
      flex: 0 0 auto; }
    .bsk-button-icon > div > svg {
      width: 2rem;
      height: 2rem; }
  .bsk-button-icon:focus {
    outline: unset; }
  .bsk-button-icon.primary {
    color: #ffffff;
    border-color: #ffc000; }
  .bsk-button-icon.primary:not(.ghost) {
    background-color: #ffc000; }
    .bsk-button-icon.primary:not(.ghost):hover {
      background-color: #bf9000;
      border-color: #bf9000; }
  .bsk-button-icon.disabled {
    border-color: #e9e9e9;
    color: #aaa; }
  .bsk-button-icon.disabled:not(.ghost) {
    background-color: #e9e9e9; }
  .bsk-button-icon.small {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    line-height: 1.4rem;
    display: inline-block;
    min-height: 2.1rem; }
    .bsk-button-icon.small span {
      line-height: 1.4rem; }
  .bsk-button-icon:last-of-type {
    margin-right: 0; }

button.bsk-button-icon span {
  font-size: inherit; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-sign-in-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: space-around;
  place-content: center; }
  .bsk-sign-in-page .bsk-sign-in-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #e2e2e2; }
    .bsk-sign-in-page .bsk-sign-in-container button {
      font-family: "Roboto"; }
    .bsk-sign-in-page .bsk-sign-in-container > .intro,
    .bsk-sign-in-page .bsk-sign-in-container > .email,
    .bsk-sign-in-page .bsk-sign-in-container > .password,
    .bsk-sign-in-page .bsk-sign-in-container > .forgotpassword,
    .bsk-sign-in-page .bsk-sign-in-container > .connect,
    .bsk-sign-in-page .bsk-sign-in-container > .signup,
    .bsk-sign-in-page .bsk-sign-in-container > .orconnectwith,
    .bsk-sign-in-page .bsk-sign-in-container > .fb-or-gg,
    .bsk-sign-in-page .bsk-sign-in-container > .register,
    .bsk-sign-in-page .bsk-sign-in-container > .back {
      flex: 0 0 auto; }
    .bsk-sign-in-page .bsk-sign-in-container > .intro,
    .bsk-sign-in-page .bsk-sign-in-container > .back {
      background-color: #afacab;
      color: #ffffff;
      padding: 1rem 2rem;
      text-align: justify;
      text-justify: inter-word;
      font-size: 1rem; }
    .bsk-sign-in-page .bsk-sign-in-container > .back {
      display: flex;
      flex-direction: row; }
      .bsk-sign-in-page .bsk-sign-in-container > .back > .text {
        padding-left: 1rem; }
      .bsk-sign-in-page .bsk-sign-in-container > .back > .text,
      .bsk-sign-in-page .bsk-sign-in-container > .back > .icon {
        flex: 0 0 auto;
        margin-right: 1rem; }
        .bsk-sign-in-page .bsk-sign-in-container > .back > .text svg,
        .bsk-sign-in-page .bsk-sign-in-container > .back > .icon svg {
          width: 1.5rem;
          height: 1.5rem; }
    .bsk-sign-in-page .bsk-sign-in-container > .email,
    .bsk-sign-in-page .bsk-sign-in-container > .forgotpassword,
    .bsk-sign-in-page .bsk-sign-in-container > .reset-password-msg,
    .bsk-sign-in-page .bsk-sign-in-container > .password {
      margin-top: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      flex-direction: row;
      position: relative; }
      .bsk-sign-in-page .bsk-sign-in-container > .email > svg,
      .bsk-sign-in-page .bsk-sign-in-container > .forgotpassword > svg,
      .bsk-sign-in-page .bsk-sign-in-container > .reset-password-msg > svg,
      .bsk-sign-in-page .bsk-sign-in-container > .password > svg {
        flex: 0 0 auto;
        width: 2rem;
        height: 2rem;
        color: #afacab;
        margin-top: 0.7rem; }
      .bsk-sign-in-page .bsk-sign-in-container > .email > div,
      .bsk-sign-in-page .bsk-sign-in-container > .forgotpassword > div,
      .bsk-sign-in-page .bsk-sign-in-container > .reset-password-msg > div,
      .bsk-sign-in-page .bsk-sign-in-container > .password > div {
        flex: 1 1 auto;
        margin-left: 1rem; }
    .bsk-sign-in-page .bsk-sign-in-container .showPassword {
      position: absolute;
      top: 0.5rem;
      right: 2rem; }
      .bsk-sign-in-page .bsk-sign-in-container .showPassword > svg {
        width: 2rem;
        height: 2rem;
        color: #afacab; }
    .bsk-sign-in-page .bsk-sign-in-container > .forgotpassword {
      margin-top: 0rem;
      color: #00b0f1; }
    .bsk-sign-in-page .bsk-sign-in-container > .reset-password-msg {
      color: #afacab; }
    .bsk-sign-in-page .bsk-sign-in-container > .connect,
    .bsk-sign-in-page .bsk-sign-in-container > .signup,
    .bsk-sign-in-page .bsk-sign-in-container > .register {
      padding-left: 1rem;
      padding-right: 1rem; }
      .bsk-sign-in-page .bsk-sign-in-container > .connect > button,
      .bsk-sign-in-page .bsk-sign-in-container > .signup > button,
      .bsk-sign-in-page .bsk-sign-in-container > .register > button {
        width: 100%; }
    .bsk-sign-in-page .bsk-sign-in-container > .fb-or-gg {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem; }
    .bsk-sign-in-page .bsk-sign-in-container > .orconnectwith {
      display: flex;
      flex-direction: row;
      font-size: 1rem;
      align-items: center; }
      .bsk-sign-in-page .bsk-sign-in-container > .orconnectwith .line {
        flex: 1 1 auto;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #afacab;
        max-height: 3px; }
      .bsk-sign-in-page .bsk-sign-in-container > .orconnectwith .text {
        flex: 0 0 auto;
        color: #afacab;
        margin-left: 1rem;
        margin-right: 1rem; }
  .bsk-sign-in-page .email-msg {
    margin-top: 0.5rem;
    margin-right: 1.25rem;
    font-size: 1rem;
    color: #aaa;
    padding-left: 4.2rem; }

.bsk-height-t1-up .bsk-sign-in-container {
  max-height: 480px; }

.bsk-width-t1-up .bsk-sign-in-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.bsk-height-t1-up .bsk-width-t1-up .bsk-sign-in-container {
  border-radius: 0.4rem; }

.bsk-fwratio-t1-down .fb-or-gg > button {
  margin-bottom: 0.5rem;
  min-height: 2rem;
  line-height: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .bsk-fwratio-t1-down .fb-or-gg > button > div > span {
    font-size: 1.3rem;
    margin-left: 0.3rem; }
  .bsk-fwratio-t1-down .fb-or-gg > button > div > svg {
    margin-top: 0.3rem;
    width: 1.3rem;
    height: 1.3rem; }

.checkbox-cgu {
  color: grey;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex; }

.checkbox-cgu .bsk-checkbox {
  margin-right: 1.3rem; }
  .checkbox-cgu .bsk-checkbox:not(.active) {
    background-color: #ffffff; }

.checklabel {
  display: block;
  position: relative;
  margin-left: 5rem;
  margin-right: 1.25rem;
  margin-bottom: 0.75rem;
  padding-left: 2.2rem;
  cursor: pointer;
  font-size: 0.75rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.checklabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.55rem;
  width: 1.55rem;
  background-color: #eee; }

/* On mouse-over, add a grey background color */
.checklabel:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the checkbox is checked, add a blue background */
.checklabel input:checked ~ .checkmark {
  background-color: #2196f3; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.checklabel input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.checklabel .checkmark:after {
  left: 0.56rem;
  top: 0.31rem;
  width: 0.31rem;
  height: 0.625rem;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.bsk-split {
  display: flex;
  flex-direction: row;
  margin-top: 1rem; }
  .bsk-split.mt-0 {
    margin-top: 0.3rem; }
  .bsk-split:not(.full) > .field {
    flex: 0 0 25%; }
  .bsk-split:not(.full) > .value {
    flex: 0 0 75%; }
  .bsk-split.full > .field {
    display: none; }
  .bsk-split.full > .value {
    flex: 0 0 100%; }
  .bsk-split .field {
    font-size: 1rem; }
  .bsk-split.text-right .field {
    text-align: right;
    width: 100%;
    padding-right: 1rem; }
  .bsk-split.value-right .value {
    text-align: right; }
  .bsk-split.flex-end > .value {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
    .bsk-split.flex-end > .value > * {
      flex: 0 0 auto; }

.bsk-settings-page {
  padding-top: 1rem;
  width: 100%;
  text-align: center; }
  .bsk-settings-page > button.bsk-button {
    margin-left: auto;
    margin-right: auto; }

.showIcon {
  width: 100%; }
  .showIcon ul {
    width: 100%;
    list-style-type: none; }
    .showIcon ul li {
      width: auto;
      float: left;
      margin-right: 10px;
      margin-bottom: 10px; }
      .showIcon ul li .icon {
        text-align: center; }
        .showIcon ul li .icon svg {
          width: 4rem;
          height: 4rem; }
      .showIcon ul li .name {
        text-align: center;
        font-size: 1.3rem; }

.iconsHeader {
  width: auto;
  text-align: center;
  margin: 1rem 5rem;
  background-color: #eee;
  padding: 0.5rem 5rem; }
  .iconsHeader h3 {
    margin-bottom: 0rem; }

.button-container {
  display: flex;
  flex-direction: row; }
  .button-container > button {
    flex: 1 1 30%; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-table {
  width: 100%;
  overflow: hidden; }
  .bsk-table > table {
    width: 100%;
    overflow: hidden; }
    .bsk-table > table > tbody > tr {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      border-bottom: 1px solid #e9e9e9; }
      .bsk-table > table > tbody > tr:hover {
        background-color: rgba(255, 192, 0, 0.3); }
      .bsk-table > table > tbody > tr:last-of-type {
        border-bottom-style: none; }
    .bsk-table > table th,
    .bsk-table > table td {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal; }
    .bsk-table > table th {
      font-size: 1.1rem;
      font-weight: 700; }
    .bsk-table > table td {
      font-size: 1rem; }
    .bsk-table > table > tbody,
    .bsk-table > table > thead,
    .bsk-table > table > tbody > tr,
    .bsk-table > table > thead > tr {
      width: 100%;
      max-width: 100%;
      overflow: hidden; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-incident-form.bg-white {
  background-color: #ffffff; }

.bsk-incident-form.pd-top {
  padding-top: 0.1rem; }

.bsk-incident-form > .title,
.bsk-incident-form > .bsk-changes-tracking > .title {
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1rem; }

.bsk-incident-form > .container {
  padding-right: 1rem;
  padding-left: 1rem; }

.bsk-form-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 1rem;
  margin-top: 1rem; }
  .bsk-form-item.warning {
    background-color: #ffe699;
    border-radius: 0.3rem;
    padding: 1rem; }
  .bsk-form-item.info {
    background-color: #ececea;
    border-radius: 0.3rem;
    padding: 1rem; }
  .bsk-form-item .left {
    text-align: left; }
  .bsk-form-item .right {
    text-align: right; }
  .bsk-form-item > .label {
    flex: 0 0 34%;
    font-weight: 700;
    color: #afacab;
    padding-right: 1rem;
    text-align: right; }
  .bsk-form-item > .field {
    flex: 0 0 66%; }
    .bsk-form-item > .field.left {
      text-align: left; }
    .bsk-form-item > .field.right {
      text-align: right; }
  .bsk-form-item.full > .field {
    flex: 1 1 100%; }

.bsk-fwratio-t2-down .bsk-form-item {
  flex-direction: column; }
  .bsk-fwratio-t2-down .bsk-form-item > .label {
    flex: 0 0 auto;
    width: 100%;
    padding-right: unset;
    text-align: left;
    font-size: 0.8rem; }
  .bsk-fwratio-t2-down .bsk-form-item > .field {
    flex: 0 0 auto; }

.bsk-form-item-mt {
  margin-top: 1rem;
  font-size: 1rem; }

.aMargin {
  margin: 3px;
}
.divImage {
  border: 1px solid #ccc;
  width: 140px;
  height: 140px;
  max-width: 140px;
  max-height: 140px;
  border-radius: 3px;
  padding: 8px;
}
.footer {
  margin-top: 10px;
}
.img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-msg-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #ff3300;
  margin-right: 0.5rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-form-icon > svg {
  width: 2rem;
  height: 2rem;
  color: #757170; }

.bsk-char-count {
  font-size: 0.8rem;
  color: #979596; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-space-white {
  height: 1rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-card {
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #e8e8e8; }
  .bsk-card > .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 700;
    font-size: 1.4rem; }
  .bsk-card > .content {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    height: 100%; }
  .bsk-card > .footer {
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #fafafa;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8; }
  .bsk-card.no-footer > .footer {
    display: none; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.linkedIncidentDrillDown {
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  color: #1a73e8; }

.linkedIncidentNameOnly {
  margin-top: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  color: #aaa; }

div.bsk-incident-view {
  margin-left: 0;
  margin-right: 0;
  font-size: 1rem; }
  div.bsk-incident-view > .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #ffffff;
    padding-bottom: 0.5rem; }
    div.bsk-incident-view > .header > div {
      padding-left: 0;
      padding-right: 0; }
    div.bsk-incident-view > .header > .title {
      flex: 0 0 100%;
      background-color: #345c66;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: left;
      font-size: 1.2rem;
      line-height: 3rem;
      padding-left: 3rem; }
      div.bsk-incident-view > .header > .title > .icon {
        display: none; }
        div.bsk-incident-view > .header > .title > .icon > svg {
          margin-left: 2rem;
          width: 1rem;
          height: 1rem; }
      div.bsk-incident-view > .header > .title > .text {
        margin-left: 1rem; }
    div.bsk-incident-view > .header > .problem-type,
    div.bsk-incident-view > .header > .address,
    div.bsk-incident-view > .header > .queue-name,
    div.bsk-incident-view > .header > .assigned-to,
    div.bsk-incident-view > .header > .previous-incidents,
    div.bsk-incident-view > .header > .next-incidents,
    div.bsk-incident-view > .header > .visibility,
    div.bsk-incident-view > .header > .tags,
    div.bsk-incident-view > .header > .tags-parent,
    div.bsk-incident-view > .header > .archived {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      margin-top: 0.5rem;
      position: relative; }
      div.bsk-incident-view > .header > .problem-type:not(.problem-type),
      div.bsk-incident-view > .header > .address:not(.problem-type),
      div.bsk-incident-view > .header > .queue-name:not(.problem-type),
      div.bsk-incident-view > .header > .assigned-to:not(.problem-type),
      div.bsk-incident-view > .header > .previous-incidents:not(.problem-type),
      div.bsk-incident-view > .header > .next-incidents:not(.problem-type),
      div.bsk-incident-view > .header > .visibility:not(.problem-type),
      div.bsk-incident-view > .header > .tags:not(.problem-type),
      div.bsk-incident-view > .header > .tags-parent:not(.problem-type),
      div.bsk-incident-view > .header > .archived:not(.problem-type) {
        align-items: center; }
      div.bsk-incident-view > .header > .problem-type > .icon,
      div.bsk-incident-view > .header > .address > .icon,
      div.bsk-incident-view > .header > .queue-name > .icon,
      div.bsk-incident-view > .header > .assigned-to > .icon,
      div.bsk-incident-view > .header > .previous-incidents > .icon,
      div.bsk-incident-view > .header > .next-incidents > .icon,
      div.bsk-incident-view > .header > .visibility > .icon,
      div.bsk-incident-view > .header > .tags > .icon,
      div.bsk-incident-view > .header > .tags-parent > .icon,
      div.bsk-incident-view > .header > .archived > .icon {
        flex: 0 0 2rem;
        width: 2rem;
        height: 2rem;
        padding-left: 0.5rem; }
      div.bsk-incident-view > .header > .problem-type > .text,
      div.bsk-incident-view > .header > .address > .text,
      div.bsk-incident-view > .header > .queue-name > .text,
      div.bsk-incident-view > .header > .assigned-to > .text,
      div.bsk-incident-view > .header > .previous-incidents > .text,
      div.bsk-incident-view > .header > .next-incidents > .text,
      div.bsk-incident-view > .header > .visibility > .text,
      div.bsk-incident-view > .header > .tags > .text,
      div.bsk-incident-view > .header > .tags-parent > .text,
      div.bsk-incident-view > .header > .archived > .text {
        margin-left: 1rem;
        flex: 1 1 auto; }
    div.bsk-incident-view > .header .bsk-incident-address-icon {
      flex: 0 0 2rem;
      width: 2rem;
      height: 2rem;
      padding-left: 0.5rem; }
    div.bsk-incident-view > .header > .problem-type {
      flex: 1 1 75%; }
      div.bsk-incident-view > .header > .problem-type > .icon {
        color: #ff3300; }
      div.bsk-incident-view > .header > .problem-type > .text {
        color: #ff3300;
        font-size: 1.2rem;
        font-weight: 700; }
    div.bsk-incident-view > .header > .status {
      flex: 1 1 25%;
      display: flex;
      flex-direction: row; }
      div.bsk-incident-view > .header > .status > .text {
        flex: 1 1 auto;
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 0.5rem; }
      div.bsk-incident-view > .header > .status > .pad {
        flex: 0 0 auto;
        margin-left: 0.5rem;
        width: 0.5rem;
        height: 2rem; }

div.bsk-incident-view .bsk-caseedit-buttons,
div.bsk-eco-mode-view .bsk-caseedit-buttons {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #ffffff; }
  div.bsk-incident-view .bsk-caseedit-buttons:not(.citizen),
  div.bsk-eco-mode-view .bsk-caseedit-buttons:not(.citizen) {
    background-color: #e5e5e5; }
  div.bsk-incident-view .bsk-caseedit-buttons > div,
  div.bsk-eco-mode-view .bsk-caseedit-buttons > div {
    flex: 1 1 1px;
    text-align: center; }
  div.bsk-incident-view .bsk-caseedit-buttons .button,
  div.bsk-incident-view .bsk-caseedit-buttons .text,
  div.bsk-eco-mode-view .bsk-caseedit-buttons .button,
  div.bsk-eco-mode-view .bsk-caseedit-buttons .text {
    width: 100%; }
  div.bsk-incident-view .bsk-caseedit-buttons .eco-top,
  div.bsk-eco-mode-view .bsk-caseedit-buttons .eco-top {
    color: #7dcbe8;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem; }
  div.bsk-incident-view .bsk-caseedit-buttons .eco-bottom,
  div.bsk-eco-mode-view .bsk-caseedit-buttons .eco-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around; }
    div.bsk-incident-view .bsk-caseedit-buttons .eco-bottom > div,
    div.bsk-eco-mode-view .bsk-caseedit-buttons .eco-bottom > div {
      flex: 0 0 20rem; }
  div.bsk-incident-view .bsk-caseedit-buttons.citizen .button,
  div.bsk-eco-mode-view .bsk-caseedit-buttons.citizen .button {
    padding-left: 1rem;
    padding-right: 1rem; }
    div.bsk-incident-view .bsk-caseedit-buttons.citizen .button .bsk-button,
    div.bsk-eco-mode-view .bsk-caseedit-buttons.citizen .button .bsk-button {
      width: 100%;
      font-size: 1.2rem;
      font-weight: 700; }
  div.bsk-incident-view .bsk-caseedit-buttons button,
  div.bsk-eco-mode-view .bsk-caseedit-buttons button {
    margin-bottom: 0.5rem; }
  div.bsk-incident-view .bsk-caseedit-buttons > .centered,
  div.bsk-eco-mode-view .bsk-caseedit-buttons > .centered {
    text-align: center; }
  div.bsk-incident-view .bsk-caseedit-buttons > .mt,
  div.bsk-eco-mode-view .bsk-caseedit-buttons > .mt {
    margin-top: 1rem; }

.bsk-fwratio-t1-down .bsk-caseedit-buttons .text {
  display: none; }

.bsk-timeline-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f6f6;
  padding-bottom: 1rem; }
  .bsk-timeline-container > .mine,
  .bsk-timeline-container > .other {
    width: 69%;
    background-color: #ffffff;
    border-radius: 1rem;
    border: 1px solid #d0cecf;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-top: 1rem; }
    .bsk-timeline-container > .mine.citizen-not-visible,
    .bsk-timeline-container > .other.citizen-not-visible {
      background-color: #e2e2e2; }
  .bsk-timeline-container > .mine {
    margin-left: 30%;
    margin-right: 1%;
    padding-bottom: 1rem; }
  .bsk-timeline-container > .other {
    margin-right: 30%;
    margin-left: 1%; }

.bsk-fwratio-t1-down .bsk-timeline-container > .mine,
.bsk-fwratio-t1-down .bsk-timeline-container > .other {
  width: 75%; }

.bsk-fwratio-t1-down .bsk-timeline-container > .mine {
  margin-left: 24%;
  margin-right: 1%; }

.bsk-fwratio-t1-down .bsk-timeline-container > .other {
  margin-right: 24%;
  margin-left: 1%; }

.bsk-tile-incident-view {
  background-color: #f6f6f6; }

/* :global .blipCodeDisplay, */
.blipCodeDisplay {
  color: #333;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  letter-spacing: 5px; }

/* :global .blipCodeExpiresIn, */
.blipCodeExpiresIn {
  color: #777;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  letter-spacing: 1px; }

/* :global .blipCodeInput, */
.blipCodeInputContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center; }
  .blipCodeInputContainer .blipCodeInput {
    flex: 0 0 3.5rem;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px; }
    .blipCodeInputContainer .blipCodeInput > input {
      flex: 0 0 1rem;
      width: 1rem; }

.blipCodeInputCenter {
  width: 100%; }

.bsk-fwratio-t1-down .blipCodeInputContainer .blipCodeInput {
  flex: 0 0 2rem;
  padding-left: 0.2rem;
  text-align: center; }
  .bsk-fwratio-t1-down .blipCodeInputContainer .blipCodeInput > input {
    flex: 0 0 0.9rem;
    margin: auto; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-sysinfo-table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .bsk-sysinfo-table .block {
    flex: 0 0 25rem; }
    .bsk-sysinfo-table .block > h1 {
      font-size: 1.3rem;
      margin-bottom: 0.5rem; }
    .bsk-sysinfo-table .block > p {
      margin-bottom: 1rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-tabs {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  margin-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .bsk-tabs > .labels {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    cursor: pointer; }
    .bsk-tabs > .labels > .label {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      margin-right: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
      -webkit-transition: color 0.3s ease-out;
      transition: color 0.3s ease-out; }
      .bsk-tabs > .labels > .label:last-of-type {
        margin-right: 0; }
      .bsk-tabs > .labels > .label.active {
        color: #bf9000; }
  .bsk-tabs.shrink > .labels {
    width: 100%;
    justify-content: space-between; }
    .bsk-tabs.shrink > .labels > .label {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-right: unset; }
  .bsk-tabs > .underline {
    flex: 0 0 auto;
    height: 4px;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e9e9e9; }
    .bsk-tabs > .underline > .box {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      background-color: #bf9000;
      position: absolute;
      height: 100%;
      width: 5rem;
      top: 2px; }
  .bsk-tabs > .tray-window {
    width: 100%;
    flex: 1 1 auto;
    position: relative; }
    .bsk-tabs > .tray-window > .tray {
      display: flex;
      flex-direction: row;
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-transition: left 0.3s ease-out;
      transition: left 0.3s ease-out; }
      .bsk-tabs > .tray-window > .tray > .bsk-tab-pane {
        flex: 0 0 100%;
        max-height: 100%;
        overflow-y: auto; }


.bsk-three-fields-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1rem; }
  .bsk-three-fields-container .left,
  .bsk-three-fields-container .right {
    flex: 0 0 25%;
    width: 100%; }
  .bsk-three-fields-container .left {
    text-align: right;
    padding-right: 1rem; }
  .bsk-three-fields-container .right {
    text-align: left;
    padding-left: 1rem; }
  .bsk-three-fields-container .center {
    flex: 0 0 50%; }

.bsk-site-queue-select {
  margin-right: 1rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-switch {
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0.3rem;
  line-height: 3rem;
  min-height: 3rem;
  font-size: 1.2rem;
  font-size: 1.2rem;
  width: 4.08rem;
  line-height: 2.4rem;
  min-height: 2.4rem;
  border-radius: 1.2rem;
  background-color: #e9e9e9;
  border-color: #e9e9e9;
  border-style: none;
  position: relative;
  cursor: pointer; }
  .bsk-switch:hover, .bsk-switch.isOpen {
    border-color: #ffc000; }
  .bsk-switch.isOpen {
    box-shadow: 0 0 2px 2px rgba(191, 144, 0, 0.2); }
  .bsk-switch .bsk-switch-active {
    width: 70%;
    height: 70%;
    margin: auto;
    position: absolute;
    left: 0.2rem;
    line-height: 1rem;
    padding-top: 0.24rem;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .bsk-switch .bsk-switch-active svg {
      margin: auto;
      color: #ffffff;
      width: 1.92rem;
      height: 1.92rem; }
  .bsk-switch.active {
    background-color: #ffc000;
    border-color: #ffc000; }
    .bsk-switch.active .bsk-switch-active {
      left: 1.86rem; }
  .bsk-switch.disabled {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    cursor: unset; }
    .bsk-switch.disabled.active {
      background-color: #ffe699;
      border-color: #ffe699; }
    .bsk-switch.disabled .bsk-switch-effect {
      display: none; }
    .bsk-switch.disabled .bsk-switch-active > svg {
      color: #ffffff; }
  .bsk-switch.active > .bsk-switch-effect {
    position: absolute;
    width: 4.08rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    border-color: #ffc000;
    border-width: 0.5rem;
    border-style: solid;
    opacity: 0.5;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .bsk-switch:hover:not(.disabled) {
    border-color: #e9e9e9; }
    .bsk-switch:hover:not(.disabled).active {
      border-color: #ffc000; }
  .bsk-switch.disabled:hover {
    border-color: #f4f4f4; }
    .bsk-switch.disabled:hover.active {
      border-color: #ffe699; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8; }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.8; }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0; } }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-profile-table {
  background-color: #e9e9e9;
  width: 100%; }
  .bsk-profile-table td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-width: 2px;
    border-color: #ffffff;
    border-left-style: none;
    border-top-style: none;
    border-bottom-style: solid; }
    .bsk-profile-table td.no-border-bottom {
      border-bottom-style: none; }
    .bsk-profile-table td:first-child {
      border-right-style: solid;
      vertical-align: top;
      min-width: 2rem; }
      .bsk-profile-table td:first-child > div {
        margin: auto;
        text-align: center;
        font-size: 1.3rem;
        font-weight: 700;
        color: #afacab; }
    .bsk-profile-table td:last-child {
      border-right-style: none;
      padding-left: 1rem;
      padding-right: 1rem; }
      .bsk-profile-table td:last-child > .title {
        color: #44546a;
        font-size: 1.3rem;
        font-weight: 700; }
      .bsk-profile-table td:last-child > .content {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row; }
        .bsk-profile-table td:last-child > .content > .name {
          flex: 1 1 20%;
          line-height: 3rem;
          color: #44546a;
          font-size: 1.3rem; }
        .bsk-profile-table td:last-child > .content > .field {
          padding-left: 0.3rem;
          flex: 1 1 80%; }
          .bsk-profile-table td:last-child > .content > .field.user-type {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center; }
            .bsk-profile-table td:last-child > .content > .field.user-type > img.type {
              width: 3rem;
              height: 3rem; }
            .bsk-profile-table td:last-child > .content > .field.user-type > svg.provider {
              width: 2rem;
              height: 2rem;
              color: #ffffff; }
              .bsk-profile-table td:last-child > .content > .field.user-type > svg.provider.fb {
                width: 3rem;
                height: 3rem;
                margin-right: 1rem; }
              .bsk-profile-table td:last-child > .content > .field.user-type > svg.provider.gg {
                margin-right: 0.5rem; }
          .bsk-profile-table td:last-child > .content > .field.alc {
            align-items: center;
            line-height: 3rem; }
    .bsk-profile-table td.map {
      padding-top: 0px;
      padding-bottom: 0px; }
    .bsk-profile-table td > .content.address-msg {
      margin-top: 0; }
      .bsk-profile-table td > .content.address-msg > .field > div {
        margin-top: 0.5rem;
        margin-right: 1.25rem;
        font-size: 1rem;
        color: #aaa; }

.bsk-btn-with-icon {
  margin-top: 0.5rem;
  width: 100%;
  max-width: 20rem;
  height: 3rem; }

.bsk-btn-with-icon-content {
  width: auto;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .bsk-btn-with-icon-content > div {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    align-content: center; }
    .bsk-btn-with-icon-content > div > svg {
      flex: 0 0 auto;
      width: 2rem;
      height: 2rem; }
    .bsk-btn-with-icon-content > div > .text {
      flex: 0 0 auto;
      padding-left: 1rem;
      font-size: 1.2rem;
      display: inline; }

.green-switch > .bsk-switch.active {
  border-color: #92d14f;
  background-color: #92d14f; }
  .green-switch > .bsk-switch.active:hover:not(.disabled) {
    border-color: #92d14f;
    background-color: #92d14f; }
  .green-switch > .bsk-switch.active > .bsk-switch-effect {
    border-color: #92d14f; }

.green-switch > .bsk-switch:not(.active) {
  border-color: #757170;
  background-color: #757170; }
  .green-switch > .bsk-switch:not(.active):hover:not(.disabled) {
    border-color: #757170;
    background-color: #757170; }
  .green-switch > .bsk-switch:not(.active) > .bsk-switch-effect {
    border-color: #757170; }

.bg-white {
  background-color: #ffffff; }

.bsk-notification-preferences > .top-msg {
  background-color: #afacab;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  width: 100%; }
  .bsk-notification-preferences > .top-msg > div {
    margin: auto;
    max-width: 15rem;
    color: #ffffff;
    text-align: center;
    font-style: italic; }

.bsk-notification-preferences > .save-button {
  text-align: center;
  background-color: #e9e9e9;
  padding-left: 1rem;
  padding-right: 1rem; }
  .bsk-notification-preferences > .save-button .bsk-btn-with-icon {
    max-width: unset; }

.bsk-profile-bottom {
  width: 100%;
  background-color: #e9e9e9;
  padding: 1rem; }
  .bsk-profile-bottom > div {
    background-color: #bec5c6;
    border-radius: 0.6rem;
    padding-bottom: 1rem; }
    .bsk-profile-bottom > div > div {
      text-align: center;
      text-decoration: underline;
      color: #ffffff;
      font-size: 1rem;
      line-height: 1.7rem; }
    .bsk-profile-bottom > div > div.title {
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-decoration: unset;
      font-size: 1.2rem;
      font-weight: 700;
      color: #979596; }

.bsk-fwratio-t1-down .bsk-profile-table td:last-of-type {
  padding-left: 0.3rem;
  padding-right: 0.3rem; }

.bsk-fwratio-t1-down .bsk-profile-table td:first-of-type {
  min-width: 1.7rem; }

.bsk-fwratio-t1-down .bsk-profile-table td.map {
  padding-left: 1rem;
  padding-right: 1rem; }
  .bsk-fwratio-t1-down .bsk-profile-table td.map .locate-me > .bsk-button {
    max-width: 12rem; }
    .bsk-fwratio-t1-down .bsk-profile-table td.map .locate-me > .bsk-button .bsk-btn-with-icon-content .text {
      font-size: 1.1rem; }

.bsk-fwratio-t1-down .bsk-profile-table td > .title {
  font-size: 1.2rem; }

.bsk-fwratio-t1-down .bsk-profile-table td > .content > .name {
  font-size: 1.1rem; }

.bsk-fwratio-t2-down .bsk-profile-table td > .content.address-msg .name {
  display: none; }

.bsk-fwratio-t2-down .bsk-profile-table td > .content.address-msg .field {
  padding-left: 0; }
  .bsk-fwratio-t2-down .bsk-profile-table td > .content.address-msg .field div {
    text-align: center; }

.bsk-profile-page div.content > h1 {
  font-weight: bold;
  text-align: center; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-notifications-top-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  .bsk-notifications-top-btns * {
    flex: 0 0 auto;
    margin-right: 1rem; }

.bsk-notifications-view > li {
  list-style: none; }
  .bsk-notifications-view > li .line {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .bsk-notifications-view > li .line > * {
      flex: 0 0 auto; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-alerts-buttons {
  width: 100%;
  text-align: right;
  padding-right: 1rem; }
  .bsk-alerts-buttons > .buttons {
    width: auto; }

.bsk-alert-content {
  margin-top: 1rem;
  padding-bottom: 1rem; }
  .bsk-alert-content > .title {
    font-size: 1.2rem;
    font-weight: 700; }
  .bsk-alert-content > p {
    margin-bottom: 0.5rem; }
  .bsk-alert-content > p.time {
    margin-top: 0.5rem; }
  .bsk-alert-content > div.mk-down {
    margin-left: 2rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-help-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(51, 51, 51, 0.2); }
  .bsk-help-center > .container {
    flex: 1 1 auto;
    background-color: #ececea;
    display: flex;
    flex-direction: column;
    overflow-y: hidden; }
    .bsk-help-center > .container > .header {
      flex: 0 0 auto;
      font-size: 1.9rem;
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      color: #ffc000;
      background-color: #ffffff; }
    .bsk-help-center > .container > .content {
      flex: 1 1 auto;
      overflow-y: auto; }
      .bsk-help-center > .container > .content > .markdown {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background-color: #ffffff;
        line-height: 1.7rem; }
        .bsk-help-center > .container > .content > .markdown.tuto {
          background-color: #ececea; }
          .bsk-help-center > .container > .content > .markdown.tuto h1 {
            text-align: center;
            color: #000000;
            font-size: 1.5rem; }
        .bsk-help-center > .container > .content > .markdown a {
          color: #0000ee; }
      .bsk-help-center > .container > .content > .icon {
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center; }
        .bsk-help-center > .container > .content > .icon > svg {
          color: #ffc000;
          height: 6rem;
          width: 6rem; }
        .bsk-help-center > .container > .content > .icon.link > svg {
          color: #27ace4; }
      .bsk-help-center > .container > .content > .button,
      .bsk-help-center > .container > .content > .buttons-box > .button {
        margin-top: 1rem;
        width: 100%;
        text-align: center; }
        .bsk-help-center > .container > .content > .button button > span,
        .bsk-help-center > .container > .content > .buttons-box > .button button > span {
          font-weight: 700;
          font-size: 1.2rem;
          letter-spacing: 0.1rem; }
      .bsk-help-center > .container > .content > .buttons-box {
        text-align: center;
        margin: auto;
        width: auto;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between; }
        .bsk-help-center > .container > .content > .buttons-box > .button {
          display: flex;
          flex-direction: row; }
          .bsk-help-center > .container > .content > .buttons-box > .button button {
            flex: 1 1 auto; }
      .bsk-help-center > .container > .content > .faq {
        margin-top: 0.5rem;
        background-color: #ffffff; }
        .bsk-help-center > .container > .content > .faq:first-of-type {
          margin-top: 1rem; }
        .bsk-help-center > .container > .content > .faq > .title-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem; }
          .bsk-help-center > .container > .content > .faq > .title-line > .title {
            flex: 1 1 100%;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1.2rem; }
          .bsk-help-center > .container > .content > .faq > .title-line > .icon {
            flex: 0 0 4rem;
            color: #ffc000;
            width: 2rem;
            padding-left: 1rem;
            padding-right: 1rem; }
        .bsk-help-center > .container > .content > .faq > .content {
          display: none;
          padding-left: 1rem;
          padding-right: 1rem; }
        .bsk-help-center > .container > .content > .faq.opened {
          padding-bottom: 1rem; }
          .bsk-help-center > .container > .content > .faq.opened > .title-line > .title {
            color: #ffc000; }
          .bsk-help-center > .container > .content > .faq.opened > .content {
            display: block; }
      .bsk-help-center > .container > .content > .v-space.base-50,
      .bsk-help-center > .container > .content > .buttons-box.base-50 {
        flex: 5 1 auto; }
      .bsk-help-center > .container > .content > .v-space.base-40,
      .bsk-help-center > .container > .content > .buttons-box.base-40 {
        flex: 4 1 auto; }
      .bsk-help-center > .container > .content > .v-space.base-30,
      .bsk-help-center > .container > .content > .buttons-box.base-30 {
        flex: 3 1 auto; }
      .bsk-help-center > .container > .content > .v-space.base-20,
      .bsk-help-center > .container > .content > .buttons-box.base-20 {
        flex: 2 1 auto; }
      .bsk-help-center > .container > .content > .v-space.base-10,
      .bsk-help-center > .container > .content > .buttons-box.base-10 {
        flex: 1 1 auto; }
      .bsk-help-center > .container > .content.flex-jb {
        display: flex;
        flex-direction: column; }
      .bsk-help-center > .container > .content > .text-link {
        text-align: center;
        font-size: 1.5rem;
        color: #27ace4; }
    .bsk-help-center > .container > .footer {
      flex: 0 0 auto; }
    .bsk-help-center > .container > .tutoFooter {
      margin-top: 2rem; }
      .bsk-help-center > .container > .tutoFooter > .steps-ctn {
        display: flex;
        flex-direction: row;
        justify-content: space-around; }
        .bsk-help-center > .container > .tutoFooter > .steps-ctn > .steps {
          display: flex;
          flex-direction: row; }
          .bsk-help-center > .container > .tutoFooter > .steps-ctn > .steps > .step {
            height: 1rem;
            width: 1rem;
            border-radius: 0.5rem;
            background-color: #d9d9d9;
            margin-left: 1rem;
            margin-right: 1rem; }
            .bsk-help-center > .container > .tutoFooter > .steps-ctn > .steps > .step.active {
              background-color: #ffc000; }
      .bsk-help-center > .container > .tutoFooter > .nav {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.5rem; }
        .bsk-help-center > .container > .tutoFooter > .nav > .btn {
          margin-left: 1rem;
          margin-right: 1rem;
          color: #27ace4;
          width: 2rem; }
        .bsk-help-center > .container > .tutoFooter > .nav > .skip {
          color: #27ace4;
          font-size: 1.3rem;
          text-decoration: underline; }
    .bsk-help-center > .container > .backFooter > .nav {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem; }
      .bsk-help-center > .container > .backFooter > .nav > .btn {
        margin-left: 1rem;
        margin-right: 1rem;
        color: #27ace4;
        width: 2rem; }

.bsk-markdown table {
  border-collapse: collapse; }
  .bsk-markdown table th,
  .bsk-markdown table td {
    padding: 0.5rem;
    border: 1px solid #bec5c6; }
  .bsk-markdown table th {
    background-color: #e9e9e9; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-welcome {
  background-color: #ffffff; }
  .bsk-welcome .hero-container {
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden; }
    .bsk-welcome .hero-container .spacer {
      flex: 0 0 50%;
      position: relative; }
      .bsk-welcome .hero-container .spacer .hero {
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .bsk-welcome .hero-container .spacer .buttons {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 0;
        height: 100%; }
        .bsk-welcome .hero-container .spacer .buttons .item {
          position: relative;
          width: 20px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone1 {
            flex: 300 1 1px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone2 {
            flex: 242 1 1px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone3 {
            flex: 157 1 1px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone4 {
            flex: 94 1 1px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone5 {
            flex: 121 1 1px; }
          .bsk-welcome .hero-container .spacer .buttons .item.zone6 {
            flex: 85 1 1px;
            -webkit-animation: mouseAnim 2s infinite;
                    animation: mouseAnim 2s infinite; }
            .bsk-welcome .hero-container .spacer .buttons .item.zone6 svg {
              width: 6rem;
              height: 6rem; }
        .bsk-welcome .hero-container .spacer .buttons .trsl {
          position: absolute;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); }
  .bsk-welcome .item .sign,
  .bsk-welcome .footer .sign {
    border-width: 0px;
    height: 3.2rem;
    font-size: 2rem;
    font-weight: bold;
    border-radius: 30px;
    min-width: 20rem;
    max-width: 20rem;
    text-align: center;
    padding-top: 0.5rem;
    font-family: "Roboto";
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    .bsk-welcome .item .sign.sign-in,
    .bsk-welcome .footer .sign.sign-in {
      background-color: #ffc000;
      color: #ffffff; }
      .bsk-welcome .item .sign.sign-in:hover,
      .bsk-welcome .footer .sign.sign-in:hover {
        background-color: #bf9000;
        color: #e5e5e5; }
    .bsk-welcome .item .sign.sign-up,
    .bsk-welcome .footer .sign.sign-up {
      background-color: #ffffff;
      color: #ffc000; }
      .bsk-welcome .item .sign.sign-up:hover,
      .bsk-welcome .footer .sign.sign-up:hover {
        background-color: #e5e5e5;
        color: #bf9000; }
  .bsk-welcome .item .welc-text {
    max-width: 40rem;
    min-width: 30rem;
    font-weight: 700;
    font-size: 3rem;
    color: #ffffff;
    text-align: center;
    font-family: "Roboto"; }
  .bsk-welcome .item .scroll-down {
    width: 1px; }
  .bsk-welcome .presentations {
    background-color: #ffffff;
    display: flex;
    flex-direction: column; }
    .bsk-welcome .presentations .presentation {
      flex: 0 1 auto;
      font-family: "Roboto";
      color: #25283d;
      padding-top: 9rem;
      padding-bottom: 6.25rem;
      position: relative;
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto; }
      .bsk-welcome .presentations .presentation .img-cont {
        position: absolute; }
        .bsk-welcome .presentations .presentation .img-cont img {
          -webkit-transform: translate(-35%, -45%);
                  transform: translate(-35%, -45%); }
      .bsk-welcome .presentations .presentation .description {
        margin-top: 2em;
        font-size: 1.25rem; }
      .bsk-welcome .presentations .presentation .title {
        margin-top: 0;
        min-height: 8.75rem;
        margin-left: 36%;
        font-size: 2.5rem; }
      .bsk-welcome .presentations .presentation .description {
        margin-left: 1.5em;
        margin-right: 1.5em;
        margin-top: 2em;
        font-size: 1.25rem; }
    .bsk-welcome .presentations.horizontal {
      flex-direction: row;
      min-height: 30rem; }
      .bsk-welcome .presentations.horizontal > .presentation {
        flex: 0 1 30rem;
        position: relative; }
        .bsk-welcome .presentations.horizontal > .presentation > .description {
          position: absolute;
          top: 20rem; }
      .bsk-welcome .presentations.horizontal > .presentation:nth-child(1) {
        margin-left: 8rem; }
  .bsk-welcome .footer {
    overflow: hidden;
    margin-top: 0px; }
    .bsk-welcome .footer .ft-top {
      height: 300px;
      background-color: #ffffff; }
    .bsk-welcome .footer .ft-bottom {
      height: 400px;
      background-color: #25283d;
      display: flex;
      flex-direction: row;
      font-family: "Roboto";
      color: #ffffff; }
    .bsk-welcome .footer .spacer {
      flex: 0 0 50%;
      position: relative; }
      .bsk-welcome .footer .spacer .drop {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        position: relative; }
        .bsk-welcome .footer .spacer .drop .over {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: row; }
    .bsk-welcome .footer .scale {
      display: flex;
      flex-direction: column;
      width: 20px; }
      .bsk-welcome .footer .scale .scale1 {
        flex: 349 1 1px; }
      .bsk-welcome .footer .scale .scale2 {
        flex: 250 1 1px; }
      .bsk-welcome .footer .scale .scale3 {
        flex: 125 1 1px; }
      .bsk-welcome .footer .scale .scale4 {
        flex: 276 1 1px; }
    .bsk-welcome .footer .trsl {
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .bsk-welcome .footer .trsl-up {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .bsk-welcome .footer .trsl-left {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    .bsk-welcome .footer .subscribe-text,
    .bsk-welcome .footer .free-text,
    .bsk-welcome .footer .top-text {
      margin: auto;
      text-align: center; }
    .bsk-welcome .footer .top-text {
      font-size: 2.5rem;
      width: 20rem; }
    .bsk-welcome .footer .subscribe-text,
    .bsk-welcome .footer .free-text {
      color: rgba(66, 66, 69, 0.6); }
    .bsk-welcome .footer .footer-signup {
      position: absolute;
      bottom: 5%; }
    .bsk-welcome .footer .free-logo {
      text-align: center;
      color: rgba(66, 66, 69, 0.6);
      font-weight: 800;
      -webkit-transform: translate(-50%, -8%);
              transform: translate(-50%, -8%); }
  .bsk-welcome .city-content {
    text-align: center;
    background-color: #ffffff; }
    .bsk-welcome .city-content .logo {
      margin-top: 5rem; }
    .bsk-welcome .city-content .text {
      font-family: "Roboto";
      font-size: 2rem;
      margin-top: 1rem;
      margin-bottom: 5rem; }
  .bsk-welcome .mouse-scroll {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
  .bsk-welcome .mouse-scroll:hover path {
    stroke: #ffffff; }
  .bsk-welcome.bsk-fhratio-3 .item .welc-text {
    max-width: 40rem;
    min-width: 30rem;
    font-size: 2.3rem; }
  .bsk-welcome.bsk-fhratio-3 .presentations .presentation .img-cont img {
    width: 15rem;
    height: 15rem; }
  .bsk-welcome.bsk-fhratio-3 div.zone2 > img {
    max-width: 150px;
    max-height: 150px; }
  .bsk-welcome.bsk-fhratio-2 .hero-container .spacer .buttons .item.zone6 svg {
    width: 4rem;
    height: 4rem; }
  .bsk-welcome.bsk-fhratio-2 .item .sign,
  .bsk-welcome.bsk-fhratio-2 .footer .sign {
    border-width: 0px;
    height: 2.4rem;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 30px;
    min-width: 15rem;
    max-width: 15rem;
    padding-top: 0.3rem; }
  .bsk-welcome.bsk-fhratio-2 .item .welc-text {
    max-width: 30rem;
    min-width: 20rem;
    font-size: 1.9rem; }
  .bsk-welcome.bsk-fhratio-2 div.zone2 > img {
    max-width: 110px;
    max-height: 110px; }
  .bsk-welcome.bsk-fhratio-2 .presentations .presentation {
    padding-top: 4rem;
    padding-bottom: 2.25rem;
    max-width: 30rem; }
    .bsk-welcome.bsk-fhratio-2 .presentations .presentation .img-cont {
      position: absolute; }
      .bsk-welcome.bsk-fhratio-2 .presentations .presentation .img-cont img {
        width: 15rem;
        height: 15rem;
        -webkit-transform: translate(-35%, -45%);
                transform: translate(-35%, -45%); }
    .bsk-welcome.bsk-fhratio-2 .presentations .presentation .description {
      margin-top: 2em;
      font-size: 1.25rem; }
    .bsk-welcome.bsk-fhratio-2 .presentations .presentation .title {
      margin-top: 0;
      min-height: 5.75rem;
      margin-left: 36%;
      font-size: 1.7rem; }
    .bsk-welcome.bsk-fhratio-2 .presentations .presentation .description {
      margin-left: 3rem;
      margin-right: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      font-size: 1.2rem; }
  .bsk-welcome.bsk-fhratio-2 .presentations.horizontal {
    flex-direction: row;
    min-height: 30rem; }
    .bsk-welcome.bsk-fhratio-2 .presentations.horizontal > .presentation {
      flex: 0 1 30rem;
      position: relative; }
      .bsk-welcome.bsk-fhratio-2 .presentations.horizontal > .presentation > .description {
        position: absolute;
        top: 20rem; }
    .bsk-welcome.bsk-fhratio-2 .presentations.horizontal > .presentation:nth-child(1) {
      margin-left: 8rem; }
  .bsk-welcome.bsk-fhratio-2 .footer {
    margin-top: 0px; }
    .bsk-welcome.bsk-fhratio-2 .footer .top-text {
      font-size: 1.9rem;
      width: 15rem; }
    .bsk-welcome.bsk-fhratio-2 .footer .subscribe-text,
    .bsk-welcome.bsk-fhratio-2 .footer .free-text {
      color: rgba(66, 66, 69, 0.6); }
    .bsk-welcome.bsk-fhratio-2 .footer .footer-signup {
      position: absolute;
      bottom: 5%; }
  .bsk-welcome.bsk-fhratio-2 .city-content {
    background-color: #ffffff; }
    .bsk-welcome.bsk-fhratio-2 .city-content .logo {
      margin-top: 2rem;
      width: 12rem;
      height: 12rem; }
    .bsk-welcome.bsk-fhratio-2 .city-content .text {
      font-family: "Roboto";
      font-size: 1.6rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem; }
  .bsk-welcome.bsk-fhratio-1 .hero-container .spacer .buttons .item.zone6 svg, .bsk-welcome.bsk-fhratio-0 .hero-container .spacer .buttons .item.zone6 svg {
    width: 3rem;
    height: 3rem; }
  .bsk-welcome.bsk-fhratio-1 .item .sign,
  .bsk-welcome.bsk-fhratio-1 .footer .sign, .bsk-welcome.bsk-fhratio-0 .item .sign,
  .bsk-welcome.bsk-fhratio-0 .footer .sign {
    border-width: 0px;
    height: 1.9rem;
    font-size: 1.3rem;
    font-weight: bold;
    border-radius: 30px;
    min-width: 15rem;
    max-width: 15rem;
    padding-top: 0.1rem; }
  .bsk-welcome.bsk-fhratio-1 .item .welc-text, .bsk-welcome.bsk-fhratio-0 .item .welc-text {
    max-width: 28rem;
    min-width: 18rem;
    font-size: 1.7rem; }
  .bsk-welcome.bsk-fhratio-1 div.zone2 > img, .bsk-welcome.bsk-fhratio-0 div.zone2 > img {
    max-width: 70px;
    max-height: 70px; }
  .bsk-welcome.bsk-fhratio-1 .presentations .presentation, .bsk-welcome.bsk-fhratio-0 .presentations .presentation {
    padding-top: 4rem;
    padding-bottom: 2.25rem;
    max-width: 30rem; }
    .bsk-welcome.bsk-fhratio-1 .presentations .presentation .img-cont, .bsk-welcome.bsk-fhratio-0 .presentations .presentation .img-cont {
      position: absolute; }
      .bsk-welcome.bsk-fhratio-1 .presentations .presentation .img-cont img, .bsk-welcome.bsk-fhratio-0 .presentations .presentation .img-cont img {
        width: 15rem;
        height: 15rem;
        -webkit-transform: translate(-35%, -45%);
                transform: translate(-35%, -45%); }
    .bsk-welcome.bsk-fhratio-1 .presentations .presentation .description, .bsk-welcome.bsk-fhratio-0 .presentations .presentation .description {
      margin-top: 2em;
      font-size: 1.25rem; }
    .bsk-welcome.bsk-fhratio-1 .presentations .presentation .title, .bsk-welcome.bsk-fhratio-0 .presentations .presentation .title {
      margin-top: 0;
      min-height: 5.75rem;
      margin-left: 36%;
      font-size: 1.5rem; }
    .bsk-welcome.bsk-fhratio-1 .presentations .presentation .description, .bsk-welcome.bsk-fhratio-0 .presentations .presentation .description {
      margin-left: 3rem;
      margin-right: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem;
      font-size: 1.25rem; }
  .bsk-welcome.bsk-fhratio-1 .presentations.horizontal, .bsk-welcome.bsk-fhratio-0 .presentations.horizontal {
    flex-direction: row;
    min-height: 30rem; }
    .bsk-welcome.bsk-fhratio-1 .presentations.horizontal > .presentation, .bsk-welcome.bsk-fhratio-0 .presentations.horizontal > .presentation {
      flex: 0 1 30rem;
      position: relative; }
      .bsk-welcome.bsk-fhratio-1 .presentations.horizontal > .presentation > .description, .bsk-welcome.bsk-fhratio-0 .presentations.horizontal > .presentation > .description {
        position: absolute;
        top: 20rem; }
    .bsk-welcome.bsk-fhratio-1 .presentations.horizontal > .presentation:nth-child(1), .bsk-welcome.bsk-fhratio-0 .presentations.horizontal > .presentation:nth-child(1) {
      margin-left: 8rem; }
  .bsk-welcome.bsk-fhratio-1 .footer, .bsk-welcome.bsk-fhratio-0 .footer {
    margin-top: 0px; }
    .bsk-welcome.bsk-fhratio-1 .footer .top-text, .bsk-welcome.bsk-fhratio-0 .footer .top-text {
      font-size: 1.5rem;
      width: 15rem; }
    .bsk-welcome.bsk-fhratio-1 .footer .subscribe-text,
    .bsk-welcome.bsk-fhratio-1 .footer .free-text, .bsk-welcome.bsk-fhratio-0 .footer .subscribe-text,
    .bsk-welcome.bsk-fhratio-0 .footer .free-text {
      color: rgba(66, 66, 69, 0.6); }
    .bsk-welcome.bsk-fhratio-1 .footer .footer-signup, .bsk-welcome.bsk-fhratio-0 .footer .footer-signup {
      position: absolute;
      bottom: 5%; }
  .bsk-welcome.bsk-fhratio-1 .city-content, .bsk-welcome.bsk-fhratio-0 .city-content {
    background-color: #ffffff; }
    .bsk-welcome.bsk-fhratio-1 .city-content .logo, .bsk-welcome.bsk-fhratio-0 .city-content .logo {
      margin-top: 2rem;
      width: 8rem;
      height: 8rem; }
    .bsk-welcome.bsk-fhratio-1 .city-content .text, .bsk-welcome.bsk-fhratio-0 .city-content .text {
      font-family: "Roboto";
      font-size: 1.3rem;
      margin-top: 0.5rem;
      margin-bottom: 3rem; }
  .bsk-welcome.bsk-fhratio-0:not(.bsk-fhratio-1) .hero-container .spacer .buttons .item.zone2 {
    display: none; }
  .bsk-welcome.bsk-fhratio-0:not(.bsk-fhratio-1) .hero-container .spacer .buttons .item.zone6 svg {
    width: 2rem;
    height: 2rem; }
  .bsk-welcome.bsk-fhratio-0:not(.bsk-fhratio-1) .item .sign,
  .bsk-welcome.bsk-fhratio-0:not(.bsk-fhratio-1) .footer .sign {
    height: 1.4rem;
    font-size: 1rem;
    border-radius: 30px;
    min-width: 15rem;
    max-width: 15rem;
    padding-top: 0.1rem; }
  .bsk-welcome.bsk-fhratio-0:not(.bsk-fhratio-1) .item .welc-text {
    max-width: 28rem;
    min-width: 18rem;
    font-size: 1.4rem; }

@-webkit-keyframes mouseAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; }
  10% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.6) translateY(20%) translateX(20%);
            transform: scale(1.6) translateY(20%) translateX(20%);
    opacity: 0; } }

@keyframes mouseAnim {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0; }
  10% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.6) translateY(20%) translateX(20%);
            transform: scale(1.6) translateY(20%) translateX(20%);
    opacity: 0; } }


.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-pop-icon-list {
  padding: 1rem;
  position: relative;
  overflow-y: hidden; }
  .bsk-pop-icon-list.very-small {
    padding-left: 0rem;
    padding-right: 0rem; }
    .bsk-pop-icon-list.very-small > .title {
      font-size: 1.1rem;
      font-weight: 700;
      padding-bottom: 1rem; }
  .bsk-pop-icon-list > .title {
    font-size: 1.1rem;
    line-height: 1.3rem;
    text-align: center;
    color: #00b0f1;
    padding-right: 2rem;
    padding-top: 0.5rem; }
  .bsk-pop-icon-list > .icon {
    position: absolute;
    right: 0.7rem;
    top: 1.2rem; }
    .bsk-pop-icon-list > .icon > svg {
      height: 1.5rem;
      width: 1.5rem;
      fill: #00b0f1; }
  .bsk-pop-icon-list > .list {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0.5rem; }
    .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item {
      flex: 1 0 auto;
      display: flex;
      flex-direction: row;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      padding-left: 0.7rem;
      padding-right: 0.7rem;
      border-radius: 0.3rem;
      align-items: center;
      margin-top: 0.5rem; }
      .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item:hover {
        background-color: #ffc000; }
      .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .icon-list {
        flex: 0 0 auto;
        width: 2rem;
        height: 2rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around; }
        .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .icon-list > svg {
          flex: 0 0 auto;
          width: 1.5rem; }
          .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .icon-list > svg.reduce-1 {
            width: 1.35rem; }
          .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .icon-list > svg.reduce-2 {
            width: 1.2rem; }
          .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .icon-list > svg.reduce-3 {
            width: 1.05rem; }
      .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item.very-small {
        padding-left: 0rem;
        padding-right: 0rem; }
        .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item.very-small > .text {
          font-size: 1rem;
          font-weight: 400; }
      .bsk-pop-icon-list > .list > .bsk-pop-icon-list-item > .text {
        flex: 0 0 auto;
        padding-left: 1rem;
        font-size: 1rem;
        width: auto;
        display: contents;
        line-height: 1.5rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-timeline-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 0.3rem;
  box-sizing: content-box; }
  .bsk-timeline-item > .problem-type,
  .bsk-timeline-item > .alert-item,
  .bsk-timeline-item > .assigned-to,
  .bsk-timeline-item > .is-archived,
  .bsk-timeline-item > .display-std,
  .bsk-timeline-item > .display-icon,
  .bsk-timeline-item > .custom-form,
  .bsk-timeline-item > .show-id,
  .bsk-timeline-item > .transfer {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 0.5rem; }
    .bsk-timeline-item > .problem-type > .field,
    .bsk-timeline-item > .alert-item > .field,
    .bsk-timeline-item > .assigned-to > .field,
    .bsk-timeline-item > .is-archived > .field,
    .bsk-timeline-item > .display-std > .field,
    .bsk-timeline-item > .display-icon > .field,
    .bsk-timeline-item > .custom-form > .field,
    .bsk-timeline-item > .show-id > .field,
    .bsk-timeline-item > .transfer > .field {
      flex: 0 0 25%;
      font-weight: 700;
      color: #afacab;
      margin-right: 1rem;
      text-align: right; }
    .bsk-timeline-item > .problem-type > .icon,
    .bsk-timeline-item > .alert-item > .icon,
    .bsk-timeline-item > .assigned-to > .icon,
    .bsk-timeline-item > .is-archived > .icon,
    .bsk-timeline-item > .display-std > .icon,
    .bsk-timeline-item > .display-icon > .icon,
    .bsk-timeline-item > .custom-form > .icon,
    .bsk-timeline-item > .show-id > .icon,
    .bsk-timeline-item > .transfer > .icon {
      flex: 0 0 25%;
      color: #afacab;
      margin-right: 1rem;
      text-align: right; }
      .bsk-timeline-item > .problem-type > .icon > svg,
      .bsk-timeline-item > .alert-item > .icon > svg,
      .bsk-timeline-item > .assigned-to > .icon > svg,
      .bsk-timeline-item > .is-archived > .icon > svg,
      .bsk-timeline-item > .display-std > .icon > svg,
      .bsk-timeline-item > .display-icon > .icon > svg,
      .bsk-timeline-item > .custom-form > .icon > svg,
      .bsk-timeline-item > .show-id > .icon > svg,
      .bsk-timeline-item > .transfer > .icon > svg {
        width: 1.2rem;
        height: 1.2rem; }
    .bsk-timeline-item > .problem-type > .text,
    .bsk-timeline-item > .alert-item > .text,
    .bsk-timeline-item > .assigned-to > .text,
    .bsk-timeline-item > .is-archived > .text,
    .bsk-timeline-item > .display-std > .text,
    .bsk-timeline-item > .display-icon > .text,
    .bsk-timeline-item > .custom-form > .text,
    .bsk-timeline-item > .show-id > .text,
    .bsk-timeline-item > .transfer > .text {
      flex: 0 0 75%;
      overflow: hidden;
      padding-right: 1rem; }
  .bsk-timeline-item > .alert-item.alert-subject > .text,
  .bsk-timeline-item > .alert-item.alert-message > .text {
    flex: 0 0 100%;
    white-space: normal;
    word-wrap: normal;
    overflow: visible; }
    .bsk-timeline-item > .alert-item.alert-subject > .text ul,
    .bsk-timeline-item > .alert-item.alert-message > .text ul {
      margin-left: 2rem;
      margin-bottom: 1em; }
  .bsk-timeline-item > .alert-item.alert-subject > .text {
    font-size: 1.3rem;
    font-weight: 700; }
  .bsk-timeline-item > .alert-item.alert-subject, .bsk-timeline-item > .alert-item.alert-message {
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem; }
  .bsk-timeline-item > .details {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1rem; }
    .bsk-timeline-item > .details > .text {
      flex: 0 0 auto;
      max-width: 95%;
      white-space: wrap; }
      .bsk-timeline-item > .details > .text.editable {
        color: #0000ee; }
        .bsk-timeline-item > .details > .text.editable > svg {
          color: #0000ee; }
      .bsk-timeline-item > .details > .text > svg {
        color: #d0cecf;
        width: 0.8rem;
        height: 0.8rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem; }
  .bsk-timeline-item > .display-images {
    text-align: left;
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem; }
    .bsk-timeline-item > .display-images > .field {
      flex: 0 0 25%;
      font-weight: 700;
      color: #afacab;
      margin-right: 1rem;
      text-align: right; }
    .bsk-timeline-item > .display-images > .images {
      flex: 1 1 75%; }
    .bsk-timeline-item > .display-images > .icon {
      flex: 0 0 25%;
      color: #afacab;
      margin-right: 1rem;
      text-align: right; }
      .bsk-timeline-item > .display-images > .icon > svg {
        width: 1.2rem;
        height: 1.2rem; }
  .bsk-timeline-item > .divider {
    width: 100%;
    height: 0.5rem;
    background-color: #e9e9e9; }
  .bsk-timeline-item > .time {
    flex: 0 0 60%;
    margin-top: 0.4rem;
    margin-left: 0.3rem;
    color: #afacab;
    font-weight: 100;
    font-size: 0.8rem; }
  .bsk-timeline-item > .created-by {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    text-align: left;
    margin-top: 0.8rem;
    font-size: 0.8rem; }
    .bsk-timeline-item > .created-by > .field {
      display: none; }
    .bsk-timeline-item > .created-by > .icon {
      flex: 0 0 2rem;
      color: #afacab;
      text-align: left;
      padding-left: 0.4rem;
      order: 1; }
      .bsk-timeline-item > .created-by > .icon > svg {
        width: 1.2rem;
        height: 1.2rem; }
    .bsk-timeline-item > .created-by > .text {
      flex: 1 1 100%;
      color: #afacab;
      order: 2; }
  .bsk-timeline-item > .status {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
    .bsk-timeline-item > .status > .text {
      flex: 0 0 100%;
      color: #ffffff;
      font-size: 1.2rem;
      font-weight: 700; }
    .bsk-timeline-item > .status > .field {
      display: none; }
  .bsk-timeline-item > .problem-type {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem; }
    .bsk-timeline-item > .problem-type > .icon {
      color: #ff3300;
      flex: 0 0 auto;
      margin-right: unset; }
    .bsk-timeline-item > .problem-type > .text {
      margin-left: 0.5rem;
      flex: 1 1 auto;
      text-align: left; }
    .bsk-timeline-item > .problem-type > .field {
      display: none; }
  .bsk-timeline-item > .padding {
    display: none; }

.bsk-fwratio-t1-down .bsk-timeline-item > .display-std {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap; }
  .bsk-fwratio-t1-down .bsk-timeline-item > .display-std > .field {
    flex: 1 0 100%;
    text-align: left;
    padding-left: 1rem;
    margin-right: unset;
    color: #e5e5e5;
    display: none; }
  .bsk-fwratio-t1-down .bsk-timeline-item > .display-std > .text {
    flex: 1 1 75%;
    overflow: hidden;
    padding-right: 0.5rem; }
  .bsk-fwratio-t1-down .bsk-timeline-item > .display-std > .padding {
    flex: 1 1 25%; }

.bsk-fwratio-t1-down .bsk-timeline-item > .display-images {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 0; }
  .bsk-fwratio-t1-down .bsk-timeline-item > .display-images > .field {
    flex: 1 0 100%;
    text-align: left;
    padding-left: 1rem;
    margin-right: unset;
    color: #e5e5e5;
    display: none; }
  .bsk-fwratio-t1-down .bsk-timeline-item > .display-images > .images {
    flex: 1 1 100%;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: unset;
    overflow: hidden; }

.bsk-visibility-flag {
  flex: 0 0 30%;
  display: flex;
  flex-direction: row;
  text-align: left;
  margin-top: 0.4rem;
  font-size: 0.8rem;
  position: relative; }
  .bsk-visibility-flag > .field {
    display: none; }
  .bsk-visibility-flag > .icon {
    flex: 0 0 2rem;
    color: #afacab;
    text-align: right;
    order: 2;
    padding-right: 0.4rem; }
    .bsk-visibility-flag > .icon > svg {
      width: 1.2rem;
      height: 1.2rem; }
  .bsk-visibility-flag > .text {
    order: 1;
    text-align: right;
    flex: 1 1 auto;
    color: #afacab; }
  .bsk-visibility-flag.locked > .text,
  .bsk-visibility-flag.locked > .icon {
    color: #ff3300; }
  .bsk-visibility-flag.openable > .text,
  .bsk-visibility-flag.openable > .icon {
    color: #0000ee; }
  .bsk-visibility-flag > .pop {
    position: absolute;
    right: 0.3rem;
    top: -0.2rem;
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    border-radius: 0.3rem; }
    .bsk-visibility-flag > .pop > .item,
    .bsk-visibility-flag > .pop > .icon {
      flex: 0 0 auto; }
    .bsk-visibility-flag > .pop > .icon {
      width: 2.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
      .bsk-visibility-flag > .pop > .icon > svg {
        fill: #00b0f1; }
    .bsk-visibility-flag > .pop > .item {
      margin-right: 0.5rem;
      padding: 0.3rem 1rem;
      border-radius: 0.2rem;
      border: 1px solid #d9d9d9; }
      .bsk-visibility-flag > .pop > .item:hover {
        color: #ffffff;
        background-color: #ffc000;
        border-color: #ffc000; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-changes-tracking > .record {
  margin-top: 1rem;
  border-radius: 0.3rem;
  background-color: #e9e9e9;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
  margin-right: 1rem; }
  .bsk-changes-tracking > .record > .text {
    width: 100%;
    text-align: left; }
  .bsk-changes-tracking > .record > .footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    width: 100%; }
    .bsk-changes-tracking > .record > .footer > div {
      flex: 0 0 auto;
      margin-left: 0.3rem;
      color: #888383;
      font-size: 0.8rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-casecreate-oncall {
  margin-left: 0rem;
  margin-right: 0rem;
  margin-bottom: 1rem;
  text-align: center;
  background-color: #ffc000;
  border-radius: 0.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .bsk-casecreate-oncall > .icon {
    flex: 0 0 3rem;
    color: #ffffff;
    font-weight: 700;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
    .bsk-casecreate-oncall > .icon > svg {
      width: 2rem; }
  .bsk-casecreate-oncall > .text {
    flex: 0 0 auto;
    font-size: 1.1rem;
    color: #ffffff;
    font-weight: 700;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.bsk-fwratio-t1-down .bsk-radio-button {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-no-incidents {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%; }
  .bsk-no-incidents > div {
    width: 100%;
    display: block; }
    .bsk-no-incidents > div > div {
      border-radius: 0.6rem;
      width: 17rem;
      margin: auto;
      background-color: #ffffff;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-left: 2rem;
      padding-right: 2rem; }
      .bsk-no-incidents > div > div > .top {
        color: #888383;
        text-align: center;
        font-size: 1.1rem; }
      .bsk-no-incidents > div > div > .bottom {
        margin-top: 1rem;
        color: #bec5c6;
        text-align: center;
        font-size: 1.1rem; }
      .bsk-no-incidents > div > div > .middle {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around; }
        .bsk-no-incidents > div > div > .middle > div > img {
          flex: 0 0 auto;
          width: 3rem;
          margin-right: 1rem; }
          .bsk-no-incidents > div > div > .middle > div > img:last-of-type {
            margin-right: 0; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-navbuttons {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0; }
  .bsk-navbuttons > .left,
  .bsk-navbuttons > .right {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 800;
    top: 0.5rem;
    border-radius: 1.5rem; }
    .bsk-navbuttons > .left.hidden,
    .bsk-navbuttons > .right.hidden {
      display: none; }
    .bsk-navbuttons > .left > svg,
    .bsk-navbuttons > .right > svg {
      position: absolute;
      top: 0.5rem;
      width: 2rem;
      height: 2rem;
      color: #757170; }
  .bsk-navbuttons > .left {
    left: 0.5rem; }
    .bsk-navbuttons > .left > svg {
      left: 0.35rem; }
  .bsk-navbuttons > .right {
    right: 0.5rem; }
    .bsk-navbuttons > .right > svg {
      right: 0.35rem;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%; }
  .bsk-navbuttons > .debug-win {
    position: absolute;
    top: 5rem;
    width: 100%;
    z-index: 2000;
    display: none; }
    .bsk-navbuttons > .debug-win > div {
      background-color: bisque;
      border-radius: 1.5rem;
      text-align: center; }

.bsk-scroller {
  overflow-y: auto;
  height: 100%; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.drawer-container {
  position: relative;
  height: 400px;
  border: 1px solid #ccc; }
  .drawer-container .main {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    height: 100%; }

.rmc-drawer-sidebar {
  padding: 16px;
  background-color: #e4f6fe; }

.rmc-drawer-left .rmc-drawer-sidebar,
.rmc-drawer-right .rmc-drawer-sidebar {
  height: 100%; }

body .rmc-drawer {
  position: relative; }

body .rmc-drawer {
  flex: 1 1 auto;
  font-family: "Roboto", sans-serif; }

.drw-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  -webkit-transition: width 0.3s ease-out;
  transition: width 0.3s ease-out; }
  .drw-right #mainAppBody {
    flex: 1 1 100%;
    width: 100%; }
  .drw-right #mainAppFooter {
    flex: 0 0 auto;
    width: 100%; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.sdb-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 0rem;
  padding: 0.6rem;
  border-radius: 0.6rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }
  .sdb-item:hover {
    background-color: rgba(255, 255, 255, 0.2); }
  .sdb-item .text {
    flex: 1 1 auto;
    letter-spacing: 0.1rem;
    text-decoration: unset; }
    .sdb-item .text .up,
    .sdb-item .text .down {
      width: 100%; }
    .sdb-item .text .up {
      font-weight: 700;
      font-size: 1.2rem;
      color: #ffffff; }
    .sdb-item .text .down {
      font-weight: 300;
      font-size: 1.2rem;
      color: #e9e9e9; }
  .sdb-item .icon {
    flex: 0 0 3rem;
    width: 3rem;
    height: 3rem;
    color: #ffc000;
    margin-left: 10px; }
    .sdb-item .icon svg {
      width: 100%;
      height: 100%; }

.drw-sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bsk-screen-normal .drw-sidebar-container {
  min-width: 20rem; }

.bsk-screen-small .drw-sidebar-container {
  min-width: 18rem; }

.drw-sidebar {
  padding-top: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex: 1 1 auto; }

.drw-sidebar-menu-container > .mc-content > a {
  text-decoration: unset; }

.drw-sidebar-pin {
  flex: 0 0 auto;
  width: 100%;
  text-align: right;
  margin-left: 1rem !important; }
  .drw-sidebar-pin svg {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: color 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    width: 2rem;
    height: 2rem;
    color: rgba(255, 255, 255, 0.5); }
  .drw-sidebar-pin.docked svg {
    color: white;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }

.bsk-screen-small .drw-sidebar-dices,
.bsk-screen-small .drw-sidebar-first-col {
  margin-top: 0rem; }

.drw-sidebar-container .drw-sidebar-scroller .drw-sidebar-first-col {
  line-height: 1.2rem;
  height: 1.2rem; }
  .drw-sidebar-container .drw-sidebar-scroller .drw-sidebar-first-col > div {
    line-height: 1.2rem;
    height: 1.2rem; }

.drw-sidebar-dices,
.drw-sidebar-first-col {
  flex: 0 0 auto;
  width: 100%;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  position: relative;
  display: table; }
  .drw-sidebar-dices svg,
  .drw-sidebar-dices > div,
  .drw-sidebar-first-col svg,
  .drw-sidebar-first-col > div {
    float: right;
    width: 2rem;
    height: 2rem;
    color: rgba(255, 255, 255, 0.5);
    margin-left: 1rem; }
    .drw-sidebar-dices svg.active,
    .drw-sidebar-dices > div.active,
    .drw-sidebar-first-col svg.active,
    .drw-sidebar-first-col > div.active {
      color: white; }
  .drw-sidebar-dices svg:first-of-type,
  .drw-sidebar-dices div:first-of-type,
  .drw-sidebar-first-col svg:first-of-type,
  .drw-sidebar-first-col div:first-of-type {
    margin-left: 0; }
  .drw-sidebar-dices div,
  .drw-sidebar-first-col div {
    text-align: center; }

.drw-sidebar-scroller {
  flex: 1 1 auto;
  overflow-y: auto; }

.drw-sidebar-footer {
  flex: 0 0 8rem;
  display: flex;
  flex-direction: row;
  height: 100%;
  border-top: 2px solid rgba(255, 255, 255, 0.6); }
  .drw-sidebar-footer > a {
    flex: 0 0 50%; }
  .drw-sidebar-footer > a > .left,
  .drw-sidebar-footer > a > .right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .drw-sidebar-footer > a > .left:hover,
    .drw-sidebar-footer > a > .right:hover {
      background-color: rgba(255, 255, 255, 0.2); }
  .drw-sidebar-footer a > .right {
    border-left: 2px solid rgba(255, 255, 255, 0.6); }
  .drw-sidebar-footer .ft-icon {
    color: #ffffff;
    margin: auto;
    width: 100%;
    text-align: center; }
    .drw-sidebar-footer .ft-icon svg {
      width: 3rem;
      height: 3rem;
      margin: auto; }
  .drw-sidebar-footer .ft-text {
    font-size: 1.2rem;
    width: 100%;
    margin: auto;
    color: #ffffff;
    text-align: center; }

.bsk-height-t1-down .drw-sidebar-footer {
  flex-basis: 4rem; }
  .bsk-height-t1-down .drw-sidebar-footer .ft-text {
    display: none; }

table {
  border-collapse: collapse; }

a:-webkit-any-link {
  color: unset;
  text-decoration: none; }

#mainAppContainer .rmc-drawer-sidebar {
  position: absolute;
  height: unset;
  z-index: 900; }

.rmc-drawer-sidebar {
  padding: 0px; }

.drw-sidebar,
.rmc-drawer-sidebar {
  background-color: #757170; }

.bsk-screen-small .drw-sidebar-menu-container:first-of-type {
  padding-top: 0.5rem; }

.drw-sidebar-menu-container {
  color: #ffffff;
  padding-bottom: 1rem; }
  .drw-sidebar-menu-container .mc-text {
    border-radius: 0.3rem;
    font-weight: 700;
    font-size: 1.3rem;
    color: #e9e9e9;
    -webkit-transition: color 0.3s ease-out, background-color 0.3s ease-out;
    transition: color 0.3s ease-out, background-color 0.3s ease-out;
    padding: 0.5rem 0.5rem; }
    .drw-sidebar-menu-container .mc-text:hover {
      background-color: rgba(255, 255, 255, 0.2); }
  .drw-sidebar-menu-container.entering > .mc-text,
  .drw-sidebar-menu-container.displayed > .mc-text {
    color: #ffffff; }
  .drw-sidebar-menu-container .mc-content {
    padding-left: 2rem; }
    .drw-sidebar-menu-container .mc-content > a {
      text-decoration: unset; }

.bsk-screen-small .drw-sidebar-menu-container {
  padding-top: 0; }
  .bsk-screen-small .drw-sidebar-menu-container .mc-text {
    display: none; }
  .bsk-screen-small .drw-sidebar-menu-container .mc-content {
    padding-left: 0; }

@-webkit-keyframes entering {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@keyframes entering {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@-webkit-keyframes leaving {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@keyframes leaving {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

.drw-sidebar-menu-container.entering .mc-content {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: entering;
          animation-name: entering;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.drw-sidebar-menu-container.leaving .mc-content {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: leaving;
          animation-name: leaving;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-font-size-container {
  position: absolute;
  background-color: cadetblue;
  opacity: 0.5;
  top: 120px;
  right: 0;
  color: white;
  line-height: normal;
  line-height: initial;
  display: flex;
  flex-direction: row;
  z-index: 2000; }
  .bsk-font-size-container.displayed {
    opacity: 1;
    min-height: 0px;
    right: unset;
    display: inline;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    padding: 8px;
    border-radius: 0.3rem;
    background-color: rgba(0, 0, 0, 0.6); }
  .bsk-font-size-container > .container > .select {
    display: flex;
    flex-direction: row; }
    .bsk-font-size-container > .container > .select > .icon {
      width: 40px;
      height: 40px;
      position: relative;
      background-color: #afacab;
      border-radius: 0.3rem; }
      .bsk-font-size-container > .container > .select > .icon.left > svg {
        position: absolute;
        bottom: 9.6px;
        left: 9.6px;
        width: 20.8px;
        height: 20.8px; }
      .bsk-font-size-container > .container > .select > .icon.right > svg {
        position: absolute;
        bottom: 3.2px;
        left: 3.2px;
        width: 33.6px;
        height: 33.6px; }
    .bsk-font-size-container > .container > .select > .close {
      position: relative;
      height: 32px;
      width: 32px;
      margin-left: 16px; }
      .bsk-font-size-container > .container > .select > .close > .icon {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        line-height: 24px; }
  .bsk-font-size-container > .container .bsk-font-size-value {
    width: 80px;
    min-width: 80px;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: 3px;
    font-size: 24px; }
  .bsk-font-size-container > .container .bsk-font-size-cursor-area {
    width: 100px;
    min-width: 100px;
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(255, 255, 255, 0.3); }
    .bsk-font-size-container > .container .bsk-font-size-cursor-area .bsk-font-size-cursor {
      position: absolute; }

.bsk-font-size-vspace-area {
  display: flex;
  flex-direction: row; }
  .bsk-font-size-vspace-area .bsk-font-size-vspace {
    flex: 0 1 100%;
    padding-left: 5px;
    padding-right: 5px; }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

#mainAppHeader {
  flex: 0 0 2rem;
  background-color: #757170;
  color: #ffffff;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  text-align: left;
  touch-action: none;
  line-height: 2.5rem; }
  #mainAppHeader > .std-icon {
    width: auto;
    float: left;
    position: relative; }
    #mainAppHeader > .std-icon > svg {
      margin-left: 1rem;
      margin-top: 0.3rem;
      width: 2rem;
      height: 2rem; }
    #mainAppHeader > .std-icon .badge {
      position: absolute;
      left: 2.3rem;
      top: 0;
      font-size: 0.8rem;
      line-height: 1rem;
      border-radius: 0.4rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem; }
    #mainAppHeader > .std-icon.alternative-icon {
      color: #757170; }
  #mainAppHeader > .std-icon.notification.token-fails > svg {
    fill: #888; }
  #mainAppHeader:not(.show) {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0; }
    #mainAppHeader:not(.show) > .std-icon,
    #mainAppHeader:not(.show) > .logo,
    #mainAppHeader:not(.show) > .network {
      display: none; }
  #mainAppHeader > .full-screen-icon {
    float: right;
    margin-right: 1rem; }
    #mainAppHeader > .full-screen-icon > svg {
      width: 2.3rem;
      height: 2.3rem; }
  #mainAppHeader > .network {
    float: right;
    margin-right: 1rem;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out; }
    #mainAppHeader > .network > svg {
      width: 2.3rem;
      height: 2.3rem; }
    #mainAppHeader > .network.online {
      color: #e5e5e5; }
    #mainAppHeader > .network.offline {
      color: #ff3300; }
    #mainAppHeader > .network.transfering {
      color: #f3db3b;
      -webkit-animation-name: transfering;
              animation-name: transfering;
      -webkit-animation-duration: 1s;
              animation-duration: 1s;
      -webkit-animation-play-state: running;
              animation-play-state: running;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
  #mainAppHeader > .logo {
    float: right;
    object-fit: contain;
    margin-right: 1rem; }
    #mainAppHeader > .logo > img {
      width: 2.7rem;
      height: 2.7rem; }

@-webkit-keyframes transfering {
  0% {
    opacity: 1; }
  10% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes transfering {
  0% {
    opacity: 1; }
  10% {
    opacity: 0; }
  50% {
    opacity: 0; }
  60% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.googleBgColor {
  background-color: #d0220a !important;
  border-color: #d0220a !important; }
  .googleBgColor:hover {
    background-color: #d0220a !important;
    border-color: #d0220a !important; }

.facebookBgColor {
  background-color: #2f5596 !important;
  border-color: #2f5596 !important; }
  .facebookBgColor:hover {
    background-color: #2f5596 !important;
    border-color: #2f5596 !important; }

.mt-2 {
  margin-top: 0.5rem; }

.bsk-incident-tag-colors {
  background-color: #afacab;
  color: #ffffff; }

.mk-down {
  text-align: left; }
  .mk-down p {
    margin-top: 0.7rem; }
  .mk-down li {
    padding-left: 0.5rem;
    margin-left: 2rem; }
  .mk-down.preview {
    padding-left: 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem; }
  .mk-down.preview.w-border {
    border-radius: 0.3rem;
    background-color: #ffffff;
    border: 1px solid #d9d9d9; }
  .mk-down.preview.w-bg {
    background-color: #f6f6f6; }
  .mk-down a:link {
    color: #0000ee; }
  .mk-down a:hover {
    text-decoration: underline; }
  .mk-down a:visited {
    color: #551a8b; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-3 {
  margin-top: 0.5rem !important; }

.bsk-screen-normal.bsk-vspace-0 .bsk-mt-5 {
  margin-top: 1.5rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-3 {
  margin-top: 1rem !important; }

.bsk-screen-normal.bsk-vspace-1 .bsk-mt-5 {
  margin-top: 3rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-3 {
  margin-top: 2rem !important; }

.bsk-screen-normal.bsk-vspace-2 .bsk-mt-5 {
  margin-top: 6rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-3 {
  margin-top: 4rem !important; }

.bsk-screen-normal.bsk-vspace-3 .bsk-mt-5 {
  margin-top: 12rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-3 {
  margin-top: 8rem !important; }

.bsk-screen-normal.bsk-vspace-4 .bsk-mt-5 {
  margin-top: 24rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-3 {
  margin-top: 16rem !important; }

.bsk-screen-normal.bsk-vspace-5 .bsk-mt-5 {
  margin-top: 48rem !important; }

ul {
  padding-left: 0px; }

.bsk-align-right {
  text-align: right; }

.bsk-align-center {
  text-align: center; }

a.no-decoration {
  text-decoration: none;
  color: unset; }

.bsk-w100 {
  width: 100%; }

.bsk-status-raw-bg {
  background-color: #02a4f8; }

.bsk-status-raw-color {
  color: #02a4f8; }

.bsk-status-opened-bg {
  background-color: #f3db3b; }

.bsk-status-opened-color {
  color: #f3db3b; }

.bsk-status-solved-bg {
  background-color: #94bd15; }

.bsk-status-solved-color {
  color: #94bd15; }

.bsk-status-rejected-bg {
  background-color: #d3d5e8; }

.bsk-status-rejected-color {
  color: #d3d5e8; }

.tile-content-split::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  background-color: #ffffff;
  display: none; }

.tile-content-split::-webkit-scrollbar-thumb {
  background-color: #f8f8f8;
  border-radius: 1rem;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out; }

.tile-content-split::-webkit-scrollbar-thumb:hover {
  background-color: #e9e9e9; }

.bsk-num-cols-1 .rb-col {
  width: 100%;
  max-width: 100%; }

.bsk-num-cols-2 .rb-col {
  width: 50%;
  max-width: 50%; }

.bsk-num-cols-3 .rb-col {
  width: 33.3333333%;
  max-width: 33.3333333%; }

.ngBlue {
  color: #78c8e6; }

.ngRed {
  color: #e74917; }

.ngYellow {
  color: #ffa600; }

ul {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0; }

.bsk-filter-item {
  display: flex;
  flex-direction: row;
  width: auto; }
  .bsk-filter-item > .icon {
    flex: 0 0 1.5rem;
    width: 1.5rem;
    height: 1rem;
    padding-right: 0.5rem;
    padding-top: 0.2rem; }
  .bsk-filter-item > .text {
    flex: 1 0 1.5rem;
    width: auto; }

.bsk-tile-incident-dashboard {
  background-color: #f6f6f6; }

body .rmc-drawer-overlay {
  z-index: 899; }

.bsk-footer-bg-bg {
  background-color: #ffc000; }

#stringCopyPaste {
  position: absolute;
  height: 1px;
  width: 1px;
  outline: unset;
  border-style: none;
  top: 0;
  right: 0;
  opacity: 0; }

.bsk-fix-align-left {
  text-align: left; }

.bsk-fix-margin-left {
  margin-left: 10px; }

.bsk-full-height {
  height: 100vh; }

.bsk-app-bg-color {
  background-color: #ffffff; }

#mainAppContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left; }

.header-text {
  margin-left: 1rem;
  font-size: 2rem;
  margin-top: auto;
  margin-bottom: auto; }

button {
  font-size: 1.5rem; }

#mainAppBody {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; }

body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  touch-action: none; }

#root {
  height: 100vh;
  width: 100%;
  overflow: hidden; }

#plusIcon {
  width: 5rem;
  height: 5rem;
  left: -2.5rem;
  top: -3rem;
  position: relative; }

#hamburgerIcon {
  width: 1.9rem;
  height: 1.9rem; }

/* SELECT BOX */
.selectBox {
  width: 100%;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: #d9d9d9;
  outline: unset;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* */ }
  .selectBox.selected, .selectBox:focus {
    border-color: #bf9000;
    box-shadow: 0 0 0 2px rgba(191, 144, 0, 0.2); }
  .selectBox .selectText {
    margin-left: 11px;
    margin-right: 11px;
    line-height: 35px; }
  .selectBox .selectIconDiv {
    position: absolute;
    right: 20px;
    top: 20%; }
    .selectBox .selectIconDiv .selectIconIcon {
      vertical-align: -0.125em;
      text-align: center;
      color: #d0cecf; }

/* INPUT BOX */
.inputText {
  display: block;
  width: 100%;
  padding: 5px 11px;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d0cecf;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: unset; }
  .inputText:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    border-color: #bf9000;
    box-shadow: 0 0 0 2px rgba(191, 144, 0, 0.2); }

/* CHECKBOX */
.divCheckBox {
  line-height: unset;
  box-sizing: content-box;
  max-height: 35px; }

.inputCheckBox {
  position: absolute;
  left: -9999px; }

.inputCheckBoxLabel {
  height: 35px;
  width: 35px;
  background-color: #ffffff;
  border: 1px solid #d0cecf;
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 0px; }

.inputCheckBoxLabel > div {
  opacity: 0;
  line-height: unset;
  font-size: 1.5em;
  padding-left: 5px;
  position: absolute;
  top: -5px; }

input.inputCheckBox:checked + label > div {
  opacity: 1; }

.selectPage {
  width: 100%;
  max-width: 100;
  overflow-x: auto; }

.selectPage > * {
  flex: 0 0 25%; }

.lri-p:last-child {
  margin-bottom: 0; }

#app-root,
#modal-root {
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; }

body {
  position: fixed;
  width: 100%; }

#modal-root {
  position: absolute;
  top: 0;
  z-index: 1000; }

.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; } }

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0; } }

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; } }

@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0; } }

@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; } }

@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; } }

@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; } }

@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1; }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0; } }

@-webkit-keyframes bgFadeEnter {
  0% {
    background-color: rgba(255, 255, 255, 0); }
  100% {
    background-color: rgba(51, 51, 51, 0.2); } }

@keyframes bgFadeEnter {
  0% {
    background-color: rgba(255, 255, 255, 0); }
  100% {
    background-color: rgba(51, 51, 51, 0.2); } }

@-webkit-keyframes bgFadeLeave {
  0% {
    background-color: rgba(51, 51, 51, 0.2); }
  100% {
    background-color: rgba(255, 255, 255, 0); } }

@keyframes bgFadeLeave {
  0% {
    background-color: rgba(51, 51, 51, 0.2); }
  100% {
    background-color: rgba(255, 255, 255, 0); } }

.bsk-bg-fade-enter {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: bgFadeEnter;
          animation-name: bgFadeEnter;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bsk-bg-fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-name: bgFadeLeave;
          animation-name: bgFadeLeave;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.bsk-select-detached-foreground.bsk-slide-up-enter.bsk-slide-up-enter-active.placement-bottomLeft,
.bsk-select-detached-foreground.slide-up-appear.slide-up-appear-active.placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn; }

.bsk-select-detached-foreground.bsk-slide-up-enter.bsk-slide-up-enter-active.placement-topLeft,
.bsk-select-detached-foreground.slide-up-appear.slide-up-appear-active.placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn; }

.bsk-select-detached-foreground.bsk-slide-up-leave.bsk-slide-up-leave-active.placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut; }

.bsk-select-detached-foreground.bsk-slide-up-leave.bsk-slide-up-leave-active.placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut; }

.bsk-slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.bsk-slide-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.bsk-slide-up-enter.bsk-slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.bsk-slide-up-leave.bsk-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.bsk-slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.bsk-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none; }

.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.testExport {
  flex: 1 0 100%; }

.bsk-pad {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.2rem; }

.bsk-screen-normal .bsk-pad {
  text-align: right; }

.bsk-screen-small .bsk-pad {
  font-size: 0.7rem; }

html {
  font-size: 62.5%; }

.fs-10 {
  font-size: 1rem; }

.fs-11 {
  font-size: 1.1rem; }

.fs-12 {
  font-size: 1.2rem; }

.fs-13 {
  font-size: 1.3rem; }

.fs-14 {
  font-size: 1.4rem; }

.fs-15 {
  font-size: 1.5rem; }

.fs-16 {
  font-size: 1.6rem; }

.fs-17 {
  font-size: 1.7rem; }

.fs-18 {
  font-size: 1.8rem; }

.fs-19 {
  font-size: 1.9rem; }

.fs-20 {
  font-size: 2rem; }

.shift-up #mainAppHeader {
  z-index: 0; }

.shift-up .rmc-drawer {
  overflow: unset; }
  .shift-up .rmc-drawer .rmc-drawer-content {
    overflow: unset; }
    .shift-up .rmc-drawer .rmc-drawer-content #mainAppBody {
      overflow: unset; }
      .shift-up .rmc-drawer .rmc-drawer-content #mainAppBody > .rb-main {
        overflow: unset; }
        .shift-up .rmc-drawer .rmc-drawer-content #mainAppBody > .rb-main .bsk-tile-incident-maplist {
          -webkit-transform: translateX(-1%) translateY(-1%);
                  transform: translateX(-1%) translateY(-1%);
          z-index: 200;
          box-shadow: 0rem 0rem 1rem #ccc; }
          .shift-up .rmc-drawer .rmc-drawer-content #mainAppBody > .rb-main .bsk-tile-incident-maplist .bsk-maplist-scroller {
            background-color: #f3f2ef; }

body .gm-ui-hover-effect {
  display: none;
  opacity: 0; }
  body .gm-ui-hover-effect:hover {
    opacity: 0; }

.bsk-font-size-2 {
  font-size: 2px; }

.bsk-font-size-3 {
  font-size: 3px; }

.bsk-font-size-4 {
  font-size: 4px; }

.bsk-font-size-5 {
  font-size: 5px; }

.bsk-font-size-6 {
  font-size: 6px; }

.bsk-font-size-7 {
  font-size: 7px; }

.bsk-font-size-8 {
  font-size: 8px; }

.bsk-font-size-9 {
  font-size: 9px; }

.bsk-font-size-10 {
  font-size: 10px; }

.bsk-font-size-11 {
  font-size: 11px; }

.bsk-font-size-12 {
  font-size: 12px; }

.bsk-font-size-13 {
  font-size: 13px; }

.bsk-font-size-14 {
  font-size: 14px; }

.bsk-font-size-15 {
  font-size: 15px; }

.bsk-font-size-16 {
  font-size: 16px; }

.bsk-font-size-17 {
  font-size: 17px; }

.bsk-font-size-18 {
  font-size: 18px; }

.bsk-font-size-19 {
  font-size: 19px; }

.bsk-font-size-20 {
  font-size: 20px; }

.bsk-font-size-21 {
  font-size: 21px; }

.bsk-font-size-22 {
  font-size: 22px; }

.bsk-font-size-23 {
  font-size: 23px; }

.bsk-font-size-24 {
  font-size: 24px; }

.bsk-font-size-25 {
  font-size: 25px; }

.bsk-font-size-26 {
  font-size: 26px; }

.bsk-font-size-27 {
  font-size: 27px; }

.bsk-font-size-28 {
  font-size: 28px; }

.bsk-font-size-29 {
  font-size: 29px; }

.bsk-font-size-30 {
  font-size: 30px; }

.bsk-font-size-31 {
  font-size: 31px; }

.bsk-font-size-32 {
  font-size: 32px; }

