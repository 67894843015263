@import "./bskColors.scss";

.bsk-fix-align-left {
  text-align: left;
}

.bsk-fix-margin-left {
  margin-left: 10px;
}

.bsk-full-height {
  height: 100vh;
}

.bsk-app-bg-color {
  background-color: $bsk-white;
}

#mainAppContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  // touch-action: none;
}

.header-text {
  margin-left: 1rem;
  font-size: 2rem;
  margin-top: auto;
  margin-bottom: auto;
}

button {
  font-size: 1.5rem;
}

#mainAppBody {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  touch-action: none;
}

#root {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

#plusIcon {
  width: 5rem;
  height: 5rem;
  left: -2.5rem;
  top: -3rem;
  position: relative;
}

#hamburgerIcon {
  width: 1.9rem;
  height: 1.9rem;
}

/* SELECT BOX */

.selectBox {
  width: 100%;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  border-color: $bsk-unselected;
  outline: unset;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  /* */
  &.selected,
  &:focus {
    border-color: $footer-unselected;
    box-shadow: 0 0 0 2px rgba($footer-unselected, 0.2);
  }

  .selectText {
    margin-left: 11px;
    margin-right: 11px;
    line-height: 35px;
  }

  .selectIconDiv {
    position: absolute;
    right: 20px;
    top: 20%;

    .selectIconIcon {
      vertical-align: -0.125em;
      text-align: center;
      color: $filter-bg;
    }
  }
}

/* INPUT BOX */

// .inputBox {
//   width: 100%;
//   border-width: 1px;
//   border-radius: 4px;
//   border-style: solid;
//   border-color: $bsk-unselected;
//   outline: unset;
//   line-height: 35px;
// }

.inputText {
  display: block;
  width: 100%;
  // height: calc(1.5em + 0.75rem + 2px);
  padding: 5px 11px;
  font-size: 1rem;
  font-weight: 400;
  // line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $filter-bg;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: unset;

  &:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    border-color: $footer-unselected;
    box-shadow: 0 0 0 2px rgba($footer-unselected, 0.2);
  }
}

/* CHECKBOX */

// .inputCheckBox {
//   height: $line-height;
//   width: $line-height;
//   background-color: $bsk-white;
//   border: 1px solid $filter-bg;
//   border-radius: 0.25rem;
//   -webkit-appearance: unset;

//   :checked {
//   }
// }

.divCheckBox {
  line-height: unset;
  box-sizing: content-box;
  max-height: $line-height;
}

.inputCheckBox {
  position: absolute;
  left: -9999px;
}

.inputCheckBoxLabel {
  height: $line-height;
  width: $line-height;
  background-color: $bsk-white;
  border: 1px solid $filter-bg;
  border-radius: 0.25rem;
  position: relative;
  margin-bottom: 0px;
}

.inputCheckBoxLabel > div {
  opacity: 0;
  line-height: unset;
  font-size: 1.5em;
  padding-left: 5px;
  position: absolute;
  top: -5px;
}

input.inputCheckBox:checked + label > div {
  // background-color: #000;
  opacity: 1;
}

.selectPage {
  width: 100%;
  max-width: 100;
  overflow-x: auto;
}
.selectPage > * {
  flex: 0 0 25%;
}

.lri-p:last-child {
  margin-bottom: 0;
}

// TEST MODAL

// #modal-root {
//   position: relative;
//   z-index: 999;
// }

#app-root,
#modal-root {
  font-family: "Roboto", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

body {
  position: fixed;
  width: 100%;
}

#modal-root {
  position: absolute;
  top: 0;
  z-index: 1000;
}

.zoom-enter,
.zoom-appear {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-leave {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.2);
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes bgFadeEnter {
  0% {
    background-color: rgba($bsk-white, 0);
  }
  100% {
    background-color: $modal-bg;
  }
}
@keyframes bgFadeLeave {
  0% {
    background-color: $modal-bg;
  }
  100% {
    background-color: rgba($bsk-white, 0);
  }
}

.bsk-bg-fade-enter {
  animation-duration: $bsk-select-transition-duration;
  animation-name: bgFadeEnter;
  animation-fill-mode: both;
}
.bsk-bg-fade-leave {
  animation-duration: $bsk-select-transition-duration;
  animation-name: bgFadeLeave;
  animation-fill-mode: both;
}

.bsk-select-detached-foreground.bsk-slide-up-enter.bsk-slide-up-enter-active.placement-bottomLeft,
.bsk-select-detached-foreground.slide-up-appear.slide-up-appear-active.placement-bottomLeft {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
}
.bsk-select-detached-foreground.bsk-slide-up-enter.bsk-slide-up-enter-active.placement-topLeft,
.bsk-select-detached-foreground.slide-up-appear.slide-up-appear-active.placement-topLeft {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
}
.bsk-select-detached-foreground.bsk-slide-up-leave.bsk-slide-up-leave-active.placement-bottomLeft {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
}
.bsk-select-detached-foreground.bsk-slide-up-leave.bsk-slide-up-leave-active.placement-topLeft {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
}

.bsk-slide-up-enter,
.slide-up-appear {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.bsk-slide-up-leave {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.bsk-slide-up-enter.bsk-slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
  animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.bsk-slide-up-leave.bsk-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
  animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.bsk-slide-up-enter,
.slide-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.bsk-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-down-leave {
  -webkit-animation-duration: $bsk-select-transition-duration;
  animation-duration: $bsk-select-transition-duration;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
  animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
  animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.testExport {
  flex: 1 0 100%;
}

.bsk-pad {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
}

.bsk-screen-normal .bsk-pad {
  text-align: right;
}

.bsk-screen-small .bsk-pad {
  font-size: 0.7rem;
}

html {
  font-size: 62.5%;
}

.fs-10 {
  font-size: 1rem;
}
.fs-11 {
  font-size: 1.1rem;
}
.fs-12 {
  font-size: 1.2rem;
}
.fs-13 {
  font-size: 1.3rem;
}
.fs-14 {
  font-size: 1.4rem;
}
.fs-15 {
  font-size: 1.5rem;
}
.fs-16 {
  font-size: 1.6rem;
}
.fs-17 {
  font-size: 1.7rem;
}
.fs-18 {
  font-size: 1.8rem;
}
.fs-19 {
  font-size: 1.9rem;
}
.fs-20 {
  font-size: 2rem;
}

// .col-auto {
//   -ms-flex: 0 0 auto;
//   flex: 0 0 auto;
//   width: auto;
//   max-width: 100%;
// }

// .col-1 {
//   -ms-flex: 0 0 8.333333%;
//   flex: 0 0 8.333333%;
//   max-width: 8.333333%;
// }

// .col-2 {
//   -ms-flex: 0 0 16.666667%;
//   flex: 0 0 16.666667%;
//   max-width: 16.666667%;
// }

// .col-3 {
//   -ms-flex: 0 0 25%;
//   flex: 0 0 25%;
//   max-width: 25%;
// }

// .col-4 {
//   -ms-flex: 0 0 33.333333%;
//   flex: 0 0 33.333333%;
//   max-width: 33.333333%;
// }

// .col-5 {
//   -ms-flex: 0 0 41.666667%;
//   flex: 0 0 41.666667%;
//   max-width: 41.666667%;
// }

// .col-6 {
//   -ms-flex: 0 0 50%;
//   flex: 0 0 50%;
//   max-width: 50%;
// }

// .col-7 {
//   -ms-flex: 0 0 58.333333%;
//   flex: 0 0 58.333333%;
//   max-width: 58.333333%;
// }

// .col-8 {
//   -ms-flex: 0 0 66.666667%;
//   flex: 0 0 66.666667%;
//   max-width: 66.666667%;
// }

// .col-9 {
//   -ms-flex: 0 0 75%;
//   flex: 0 0 75%;
//   max-width: 75%;
// }

// .col-10 {
//   -ms-flex: 0 0 83.333333%;
//   flex: 0 0 83.333333%;
//   max-width: 83.333333%;
// }

// .col-11 {
//   -ms-flex: 0 0 91.666667%;
//   flex: 0 0 91.666667%;
//   max-width: 91.666667%;
// }

// .col-12 {
//   -ms-flex: 0 0 100%;
//   flex: 0 0 100%;
//   max-width: 100%;
// }

.shift-up {
  #mainAppHeader {
    z-index: 0;
  }

  .rmc-drawer {
    overflow: unset;

    .rmc-drawer-content {
      overflow: unset;

      #mainAppBody {
        overflow: unset;

        & > .rb-main {
          overflow: unset;

          .bsk-tile-incident-maplist {
            transform: translateX(-1%) translateY(-1%);
            z-index: 200;
            box-shadow: 0rem 0rem 1rem #ccc;
            // overflow: hidden;

            .bsk-maplist-scroller {
              // to fix a 2 px column, color of map background.
              background-color: #f3f2ef;
            }
          }
        }
      }
    }
  }
}

body .gm-ui-hover-effect {
  display: none;
  opacity: 0;
  &:hover {
    opacity: 0;
  }
}
