.bsk-font-size-2 {
  font-size: 2px;
}

.bsk-font-size-3 {
  font-size: 3px;
}

.bsk-font-size-4 {
  font-size: 4px;
}

.bsk-font-size-5 {
  font-size: 5px;
}

.bsk-font-size-6 {
  font-size: 6px;
}

.bsk-font-size-7 {
  font-size: 7px;
}

.bsk-font-size-8 {
  font-size: 8px;
}

.bsk-font-size-9 {
  font-size: 9px;
}

.bsk-font-size-10 {
  font-size: 10px;
}

.bsk-font-size-11 {
  font-size: 11px;
}

.bsk-font-size-12 {
  font-size: 12px;
}

.bsk-font-size-13 {
  font-size: 13px;
}

.bsk-font-size-14 {
  font-size: 14px;
}

.bsk-font-size-15 {
  font-size: 15px;
}

.bsk-font-size-16 {
  font-size: 16px;
}

.bsk-font-size-17 {
  font-size: 17px;
}

.bsk-font-size-18 {
  font-size: 18px;
}

.bsk-font-size-19 {
  font-size: 19px;
}

.bsk-font-size-20 {
  font-size: 20px;
}

.bsk-font-size-21 {
  font-size: 21px;
}

.bsk-font-size-22 {
  font-size: 22px;
}

.bsk-font-size-23 {
  font-size: 23px;
}

.bsk-font-size-24 {
  font-size: 24px;
}

.bsk-font-size-25 {
  font-size: 25px;
}

.bsk-font-size-26 {
  font-size: 26px;
}

.bsk-font-size-27 {
  font-size: 27px;
}

.bsk-font-size-28 {
  font-size: 28px;
}

.bsk-font-size-29 {
  font-size: 29px;
}

.bsk-font-size-30 {
  font-size: 30px;
}

.bsk-font-size-31 {
  font-size: 31px;
}

.bsk-font-size-32 {
  font-size: 32px;
}
