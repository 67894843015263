/* :global .blipCodeDisplay, */
.blipCodeDisplay {
  color: #333;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
  letter-spacing: 5px;
}

/* :global .blipCodeExpiresIn, */
.blipCodeExpiresIn {
  color: #777;
  font-size: 1.1rem;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}

/* :global .blipCodeInput, */

.blipCodeInputContainer {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content: center;
  // display: table;

  .blipCodeInput {
    flex: 0 0 3.5rem;
    text-align: center;
    margin-left: 3px;
    margin-right: 3px;

    & > input {
      flex: 0 0 1rem;
      width: 1rem;
    }
  }
}

.blipCodeInputCenter {
  width: 100%;
}

.bsk-fwratio-t1-down {
  .blipCodeInputContainer {
    .blipCodeInput {
      flex: 0 0 2rem;
      // width: 2rem;
      // max-width: 2rem;
      padding-left: 0.2rem;
      text-align: center;

      & > input {
        flex: 0 0 0.9rem;
        // width: 0.9rem;
        // max-width: 0.9rem;
        margin: auto;
      }
    }
  }
}
