@import "../../styles/bsk/bskColors.scss";

#mainAppHeader {
  flex: 0 0 2rem;
  background-color: $header-bg;
  color: $bsk-white;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  text-align: left;
  touch-action: none;
  line-height: 2.5rem;
  // transition: all $bsk-transition-duration ease-out,
  //   max-height $bsk-transition-duration ease-out;
  // needed to ensure transition from open to zero and vice-versa
  // max-height: 4rem;

  & > .std-icon {
    width: auto;
    float: left;
    position: relative;

    & > svg {
      margin-left: 1rem;
      margin-top: 0.3rem;

      width: 2rem;
      height: 2rem;
    }

    .badge {
      position: absolute;
      left: 2.3rem;
      top: 0;
      font-size: 0.8rem;
      line-height: 1rem;
      // background-color: rgba(#f00, 0.5);
      border-radius: 0.4rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }

    &.alternative-icon {
      color: $header-bg;
    }
  }

  & > .std-icon.notification.token-fails {
    & > svg {
      fill: #888;
    }
  }

  &:not(.show) {
    // height: 0;
    // max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;

    & > .std-icon,
    & > .logo,
    & > .network {
      display: none;
    }
  }

  & > .full-screen-icon {
    float: right;
    margin-right: 1rem;

    // position: absolute;
    // right: 1.3rem;
    // top: 0.2rem;

    & > svg {
      width: 2.3rem;
      height: 2.3rem;
    }
  }

  & > .network {
    float: right;
    margin-right: 1rem;

    transition: all $bsk-transition-duration ease-out;

    & > svg {
      width: 2.3rem;
      height: 2.3rem;
    }

    &.online {
      // opacity: 1;
      // color: $bsk-solved-beacon;
      color: $filter-box;
    }

    &.offline {
      color: $bsk-warning;
    }

    &.transfering {
      color: $bsk-opened-text;
      animation-name: transfering;
      animation-duration: 1s;
      animation-play-state: running;
      animation-iteration-count: infinite;
    }
  }

  & > .logo {
    float: right;
    // width: 2rem;
    // height: 2rem;
    object-fit: contain;
    margin-right: 1rem;

    & > img {
      width: 2.7rem;
      height: 2.7rem;
    }
  }
}

@keyframes transfering {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
